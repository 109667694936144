import React from "react";
import { Typography } from "@material-tailwind/react";
import Image from "components/Image";

const NoDataOffers = () => {
  return (
    <div className="mt-16">
      <div className="flex flex-col justify-center items-center gap-4 mt-16">
        <div className="w-[220px] h-[220px]">
          <Image
            className="relative mx-auto"
            src="/assets/nodata.svg"
            alt="no-data"
            fill
            priority
          />
        </div>
        <Typography className="text-center text-base">
          Check Back Soon
        </Typography>
      </div>
    </div>
  );
};

export default NoDataOffers;
