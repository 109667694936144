import React from "react";
import { IconChevronLeft } from "@tabler/icons-react";
import { Button } from "@material-tailwind/react";

const SectionButtonStep = ({ step, onGoNext, onGoPrev }) => {
  return (
    <div className="section-btn">
      <div className="mt-16 flex flex-row justify-between">
        <div className="self-start">
          {step !== 0 && (
            <Button
              onClick={() => onGoPrev()}
              disabled={step <= 0}
              size="md"
              className="bg-transparent text-primary border-primary border flex items-center py-2 pr-8 pl-6 font-poppins font-medium rounded-xl tracking-wide"
            >
              <IconChevronLeft />
              Back
            </Button>
          )}
        </div>
        <div className="self-end">{onGoNext}</div>
      </div>
    </div>
  );
};

export default SectionButtonStep;
