import React, { useState, useRef } from "react";
import { IconClock } from "@tabler/icons-react";
import useOutsideClick from "lib/hooks/useOutsideClick";

const ComponentSetDate = ({ payload, setPayload, error, setError }) => {
  const modalRef = useRef();
  const [showTime, setShowTime] = useState(false);
  const [convertTime, setConvertTime] = useState(null);
  const TIMEDEFAULT = [
    { id: 0, name: "08:00 AM", value: "08:00" },
    { id: 1, name: "08:30 AM", value: "08:30" },
    { id: 2, name: "09:00 AM", value: "09:00" },
    { id: 3, name: "09:30 AM", value: "09:30" },
    { id: 4, name: "10:00 AM", value: "10:00" },
    { id: 5, name: "10:30 AM", value: "10:30" },
    { id: 6, name: "11:00 AM", value: "11:00" },
    { id: 7, name: "11:30 AM", value: "11:30" },
    { id: 8, name: "13:00 PM", value: "13:00" },
    { id: 9, name: "13:30 PM", value: "13:30" },
    { id: 10, name: "14:00 PM", value: "14:00" },
    { id: 11, name: "14:30 PM", value: "14:30" },
    { id: 12, name: "15:00 PM", value: "15:00" },
    { id: 13, name: "15:30 PM", value: "15:30" },
    { id: 14, name: "16:00 PM", value: "16:00" },
    { id: 15, name: "16:30 PM", value: "16:30" },
    { id: 16, name: "17:00 PM", value: "17:00" },
  ];

  useOutsideClick(() => {
    setShowTime(false);
  }, modalRef);

  const onPickTime = (params) => {
    setPayload({
      ...payload,
      appointment_time: params.name,
    });
    setConvertTime(params);
    setShowTime(false);
    setError({
      ...error,
      time: false,
    });
  };

  return (
    <div className="relative w-full" ref={modalRef}>
      <div
        className="py-3 px-4 flex flex-row justify-between items-center border-2 rounded-xl w-full cursor-pointer"
        onClick={() => setShowTime(true)}
      >
        <span className="font-medium text-base">
          {convertTime ? convertTime.name : `Select Time`}
        </span>
        <IconClock />
      </div>
      {error.time && (
        <span className="text-sm mt-1 text-red-500 block">
          Plase select time first
        </span>
      )}
      {showTime && (
        <div className="absolute top-[60px] left-0 rounded-md shadow-md border-gray-300 border bg-white z-50 max-h-[200px] overflow-y-scroll">
          <div className="w-[200px] py-4">
            <span className="font-bold text-[18px] px-4">Time</span>
            <div className="mt-2 flex flex-col">
              {TIMEDEFAULT.map((item) => {
                return (
                  <div
                    key={item.id}
                    onClick={() => onPickTime(item)}
                    className={`${
                      convertTime?.id === item.id ? "bg-primary text-white" : ""
                    } hover:bg-primary hover:text-white pl-4 py-2 cursor-pointer select-none`}
                  >
                    {item.name}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ComponentSetDate;
