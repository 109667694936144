import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Dialog } from "@headlessui/react";
import { Button, Typography } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { storeRemoveAccessToken } from "lib/network/jwt-token-access";
import { signOutRequest } from "store/action/authActions";
import { fetchProfile } from "store/action/profileActions";
import { fetchMasterData } from "store/action/masterDataActions";
import {
  IconChevronLeft,
  IconMenu2,
  IconUserCircle,
  IconLogout,
} from "@tabler/icons-react";
import { LinkPageData, DataMenu } from "./DataMenu";

const navigation = LinkPageData;

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMenuActive = (menuPath) => location.pathname.startsWith(menuPath);
  const profileData = useSelector((state) => state.Profile);
  const { data } = profileData;

  useEffect(() => {
    dispatch(fetchProfile());
    dispatch(fetchMasterData());
  }, [dispatch]);

  const logout = () => {
    dispatch(signOutRequest());
    storeRemoveAccessToken();
    navigate("/login");
  };

  return (
    <header className="relative z-10">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between px-6 pt-6 pb-8 lg:px-8"
        aria-label="Global"
      >
        <div className="flex" />
        <div className="flex items-center gap-x-12">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Loanly Company</span>
            <img
              className="h-10 w-auto"
              src="/assets/loanly_white.webp"
              alt=""
            />
          </a>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className={`text-base leading-6 text-gray-900 ${
                  item.href === location.pathname
                    ? "font-semibold"
                    : "font-normal"
                }`}
              >
                {item.name}
              </a>
            ))}
          </div>
        </div>
        <div className="flex">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <IconMenu2 className="h-7 w-7" aria-hidden="true" />
          </button>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-primary px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="absolute left-0 top-0 z-0">
            <img
              className="h-full w-auto"
              src="/assets/sidebar-mobile-top.png"
              alt=""
            />
          </div>
          <div className="absolute right-0 bottom-0 z-0">
            <img
              className="h-[100px] w-auto"
              src="/assets/sidebar-mobile-bottom.png"
              alt=""
            />
          </div>
          <div className="flex flex-col justify-between h-full ">
            <div className="relative z-10">
              <div className="flex items-center justify-between">
                <div className="flex" />
                <a href="/" className="-m-1.5 p-1.5">
                  <span className="sr-only">Loanly Company</span>
                  <img
                    className="h-10 w-auto"
                    src="/assets/loanly_white.webp"
                    alt=""
                  />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <IconChevronLeft
                    className="h-7 w-7 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
              <div className="w-full flex flex-col mt-3 justify-center items-center z-10">
                {data?.profile_picture ? (
                  <img
                    src={data?.profile_picture}
                    alt="avatar"
                    className="mt-8 relative inline-block object-cover object-center w-24 h-24 rounded-2xl"
                  />
                ) : (
                  <div className="bg-gray-100 mt-8 rounded-2xl w-24 h-24 flex justify-center items-center">
                    <IconUserCircle className=" w-20 h-20 text-blue-gray-500" />
                  </div>
                )}
                <Typography
                  variant="small"
                  className="mt-2 text-white text-base"
                >
                  {data?.name ? data?.name : "-"}
                </Typography>
              </div>
              <div className="flex flex-col flex-1 my-10 pl-8 -mr-6">
                {DataMenu.map((item, idx) => {
                  return (
                    <div className="relative" key={idx}>
                      {isMenuActive(item.url) && (
                        <div className="bg-gray-bg h-[30px] w-[30px] absolute right-0 top-[-30px]">
                          <div className="rounded-br-[30px] bg-primary h-[30px] w-full" />
                        </div>
                      )}
                      <Link
                        to={item.url}
                        className={`
                ${
                  isMenuActive(item.url)
                    ? "bg-gray-bg text-[#0C1C29] font-bold rounded-l-[30px]"
                    : "text-white"
                } py-6 px-8 flex flex-row items-center gap-3 cursor-pointer
              `}
                        key={idx}
                      >
                        {item.icon}
                        {item.name}
                      </Link>
                      {isMenuActive(item.url) && (
                        <div className="bg-gray-bg h-[30px] w-[30px] absolute right-0 bottom-[-30px]">
                          <div className="rounded-tr-[30px] bg-primary h-[30px] w-full" />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex flex-col gap-4 w-full relative mb-6 z-10 px-8">
              <Button
                onClick={() => logout()}
                size="lg"
                className="bg-white text-red-500 border-white border flex items-center py-3 font-poppins font-semibold rounded-2xl tracking-wide justify-center"
              >
                <IconLogout />
                Log Out
              </Button>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default Header;
