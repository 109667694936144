import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IconChevronLeft } from "@tabler/icons-react";
import { Typography, Button } from "@material-tailwind/react";
import AccordionPage from "pages/public/Application/ComponentsPage/AccordionPage";
import CardHeaderReview from "../ComponentsPage/CardHeaderReview";
import CardCalculation from "../ComponentsPage/CardCalculation";
import { dataSectionQuestion } from "../../../public/Application/DefaultData";
import { API_URL, get } from "lib/network/api";
import toast from "react-hot-toast";
import TitlePage from "components/TitlePage";

const ReviewOffers = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchGetDetailLoan = () => {
      setLoading(true);
      get(`${API_URL}/loan/offerings/${id}`)
        .then((res) => {
          setData(res.data);
          setTimeout(() => {
            setLoading(false);
          }, 1500);
        })
        .catch((err) => {
          toast.error("Something Went Wrong");
          setLoading(false);
        });
    };

    fetchGetDetailLoan();
  }, [id]);

  const goToBookAppointment = () => {
    navigate(`/offers/review-offers/book-appointment/${id}`);
  };

  const goBack = () => {
    navigate(-1);
  };

  if (loading) {
    return (
      <div>
        <TitlePage
          title="Review Offers"
          subtitle="Please Review the detail below and confirm this pre-approved offer from"
        />

        <div className="max-w-xl mx-auto my-10 flex flex-col gap-5 animate-pulse">
          <Typography
            as="div"
            variant="h1"
            className="h-[240px] rounded-3xl bg-gray-300"
          >
            &nbsp;
          </Typography>
          <Typography
            as="div"
            variant="h1"
            className="h-[167px] rounded-3xl bg-gray-300"
          >
            &nbsp;
          </Typography>
          <div className="flex flex-col gap-4 w-full">
            <Button
              size="md"
              className="bg-primary text-white border-primary border flex items-center py-3.5 font-poppins font-medium rounded-2xl tracking-wide justify-center"
            >
              CONFIRM OFFER
            </Button>
            <Button
              size="md"
              className="bg-white text-primary border-primary border flex items-center py-3 font-poppins font-semibold rounded-2xl tracking-wide justify-center"
            >
              <IconChevronLeft />
              BACK TO OFFER LIST
            </Button>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-3xl">
            <div className="pb-3 mb-3 border-b-[3px] border-neutral-200 ">
              <span className="text-base font-semibold">Common Questions</span>
            </div>
            <div className="flex flex-col gap-2">
              {dataSectionQuestion.map((item, idx) => {
                return (
                  <AccordionPage
                    key={idx}
                    title={item.title}
                    description={item.description}
                    index={idx}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <TitlePage
        title="Review Offers"
        subtitle="Please Review the detail below and confirm this pre-approved offer from"
      />
      <div className="max-w-xl mx-auto my-10 flex flex-col gap-5">
        <CardHeaderReview item={data} />
        <CardCalculation item={data} />
        <div className="flex flex-col gap-4 w-full">
          <Button
            onClick={() => goToBookAppointment()}
            size="md"
            className="bg-primary text-white border-primary border flex items-center py-3.5 font-poppins font-medium rounded-2xl tracking-wide justify-center"
          >
            CONFIRM OFFER
          </Button>
          <Button
            onClick={() => goBack()}
            size="md"
            className="bg-white text-primary border-primary border flex items-center py-3 font-poppins font-semibold rounded-2xl tracking-wide justify-center"
          >
            <IconChevronLeft />
            BACK TO OFFER LIST
          </Button>
        </div>
        <div className="bg-white shadow-lg p-6 rounded-3xl">
          <div className="pb-3 mb-3 border-b-[3px] border-neutral-200 ">
            <span className="text-base font-semibold">Common Questions</span>
          </div>
          <div className="flex flex-col gap-2">
            {dataSectionQuestion.map((item, idx) => {
              return (
                <AccordionPage
                  key={idx}
                  title={item.title}
                  description={item.description}
                  index={idx}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewOffers;
