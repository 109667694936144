import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Typography, Button, Tooltip } from "@material-tailwind/react";
import {
  IconBuilding,
  IconPencil,
  IconLock,
  IconChevronRight,
  IconUserFilled,
} from "@tabler/icons-react";
import moment from "moment";
import TitlePage from "components/TitlePage";

const Profile = () => {
  const navigate = useNavigate();
  const profileData = useSelector((state) => state.Profile);
  const { data } = profileData;

  const onChangePassword = () => {
    navigate("/profile/change-password");
  };

  const onEditProfile = () => {
    navigate("/profile/edit-profile");
  };

  return (
    <div>
      <TitlePage title="Profile" />
      <div className="max-w-3xl mx-auto my-10 flex flex-col gap-5">
        <div className="relative flex w-full h-fit flex-col gap-4 bg-white bg-clip-border rounded-3xl shadow-xl">
          <div className="flex flex-row gap-5 justify-between items-start px-6">
            <div className="relative py-6 pl-6">
              <div className="bg-[#F0F7FF] rounded-b-[50px] w-[70px] h-[130px] absolute top-0 left-[6px]" />
              {data?.profile_picture ? (
                <img
                  src={data?.profile_picture}
                  alt="avatar"
                  className="relative inline-block object-cover object-center w-20 h-20 rounded-lg z-10"
                />
              ) : (
                <Tooltip
                  content="No Profile Picture"
                  placement="bottom"
                  className="border border-blue-gray-50 bg-[#323232] px-2 py-1 shadow-xl shadow-black/10 text-white"
                >
                  <div className="bg-gray-100 relative rounded-lg z-10 w-20 h-20 flex justify-center items-center shadow-xl">
                    <IconUserFilled className=" w-16 h-16 text-blue-400" />
                  </div>
                </Tooltip>
              )}
            </div>
            <div className="flex-1 py-6">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <Typography
                    variant="small"
                    className="font-medium text-dark-gray text-sm"
                  >
                    Name
                  </Typography>
                  <span className="text-secondary font-medium text-lg">
                    {data?.name ? data?.name : "-"}
                  </span>
                </div>
                <div className="flex flex-col">
                  <Typography
                    variant="small"
                    className="font-medium text-dark-gray text-sm"
                  >
                    Date of Birth
                  </Typography>
                  <span className="text-secondary font-medium text-lg">
                    {data?.date_of_birth
                      ? moment(data?.date_of_birth).format("DD MMMM YYYY")
                      : "-"}
                  </span>
                </div>
                <div className="flex flex-col">
                  <Typography
                    variant="small"
                    className="font-medium text-dark-gray text-sm"
                  >
                    Email
                  </Typography>
                  <span className="text-secondary font-medium text-lg">
                    {data?.email ? data?.email : "-"}
                  </span>
                </div>
                <div className="flex flex-col">
                  <Typography
                    variant="small"
                    className="font-medium text-dark-gray text-sm"
                  >
                    Phone Number
                  </Typography>
                  <span className="text-secondary font-medium text-lg">
                    {data?.phone_number ? data?.phone_number : "-"}
                  </span>
                </div>
              </div>
              <div className="hidden lg:block">
                <div className="flex flex-col w-full mt-4">
                  <Typography
                    variant="small"
                    className="font-medium text-dark-gray text-sm"
                  >
                    Address
                  </Typography>
                  <div className="rounded-xl mt-1 p-5 flex flex-row gap-4 bg-[#DFF3FC] w-full flex-1">
                    <IconBuilding className="text-primary" />
                    <span className="font-medium">
                      {data?.address ? data?.address : "-"}
                    </span>
                  </div>
                </div>

                <div className="w-full mt-6">
                  <Button
                    onClick={() => onEditProfile()}
                    size="md"
                    className="bg-white text-primary border-primary border flex w-full gap-2 items-center py-2.5 font-poppins font-semibold rounded-xl tracking-wide justify-center"
                  >
                    <IconPencil />
                    EDIT PROFILE
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="block lg:hidden px-6 pb-6">
            <div className="flex flex-col w-full">
              <Typography
                variant="small"
                className="font-medium text-dark-gray text-sm"
              >
                Address
              </Typography>
              <div className="rounded-xl mt-1 p-5 flex flex-row gap-4 bg-[#DFF3FC] w-full flex-1">
                <IconBuilding className="text-primary" />
                <span className="font-medium">
                  {data?.address ? data?.address : "-"}
                </span>
              </div>
            </div>

            <div className="w-full mt-6">
              <Button
                onClick={() => onEditProfile()}
                size="md"
                className="bg-white text-primary border-primary border flex w-full gap-2 items-center py-2.5 font-poppins font-semibold rounded-xl tracking-wide justify-center"
              >
                <IconPencil />
                EDIT PROFILE
              </Button>
            </div>
          </div>
        </div>
        <div
          className="relative flex w-full h-fit flex-row gap-4 bg-white bg-clip-border justify-between items-center rounded-3xl shadow-xl px-6 py-4 cursor-pointer"
          onClick={() => onChangePassword()}
        >
          <div className="flex flex-row gap-3 items-center ">
            <IconLock />
            <span className="font-semibold text-sm">CHANGE PASSWORD</span>
          </div>
          <IconChevronRight className="text-primary w-9 h-9" />
        </div>
      </div>
    </div>
  );
};

export default Profile;
