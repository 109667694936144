import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Spinner } from "@material-tailwind/react";
import axios from "axios";

import Image from "components/Image";
import InputComponent from "components/InputComponent";
import InputPassword from "components/InputPassword";
import toast from "react-hot-toast";
import SideBanner from "./ComponentsPage/SideBanner";
import SideContent from "./ComponentsPage/SideContent";

import { LoginSchema } from "./SchemaValidation";
import { API_URL } from "lib/network/api";
import { storeAccessToken } from "lib/network/jwt-token-access";

export default function Login() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const API_URL_MARKETING = process.env.REACT_APP_URL_BROWSER_MARKETING;

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      handleOnSubmit(values);
    },
  });

  const handleOnSubmit = async (values) => {
    setIsLoading(true);
    const data = {
      email: values.email,
      password: values.password,
    };
    axios
      .post(`${API_URL}/tokens/authentication`, data)
      .then((res) => {
        const { data } = res;
        setIsLoading(false);
        storeAccessToken(data.data.token);
        toast.success("Successfully Login");
        navigate("/");
      })
      .catch((error) => {
        const { response } = error;
        toast.error(response.data.message);
        setIsLoading(false);
      });
  };

  return (
    <div className="flex min-h-full flex-col lg:flex-row">
      <SideBanner />
      <SideContent>
        <div className="w-[182px] relative">
          <Image
            className="relative mx-auto"
            src="/assets/loanly.webp"
            alt="Your Company"
            fill
            priority
          />
        </div>
        <div className="mt-8 flex flex-col gap-4">
          <h3 className="text-2xl font-bold">Login</h3>
          <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </span>
        </div>

        <div className="mt-8">
          <div>
            <form
              className="space-y-4"
              action="#"
              onSubmit={formik.handleSubmit}
            >
              <InputComponent
                name="email"
                type="text"
                label="Email Address"
                formik={formik}
              />
              <InputPassword name="password" label="Password" formik={formik} />
              <div className="flex flex-row justify-end">
                <Link
                  to="/forgot-password"
                  className="text-primary hover:text-secondary text-sm font-semibold"
                >
                  Forgot Password
                </Link>
              </div>
              <div>
                <button
                  disabled={isLoading}
                  type="submit"
                  className="flex w-full relative justify-center rounded-md bg-primary px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-dark"
                >
                  {isLoading ? (
                    <Spinner className="h-6 w-6" color="white" />
                  ) : (
                    "LOG IN"
                  )}
                </button>
              </div>
            </form>
            <p className="mt-6 text-center text-sm text-dark-gray">
              Don&apos;t have account?{" "}
              <Link
                to={`${API_URL_MARKETING}/application`}
                className="text-secondary hover:text-primary font-semibold"
                target="_blank"
              >
                Create Application
              </Link>
            </p>
          </div>
        </div>
      </SideContent>
    </div>
  );
}
