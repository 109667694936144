import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button, Input, Spinner } from "@material-tailwind/react";
import { fetchProfile } from "store/action/profileActions";
import { IconCalendar } from "@tabler/icons-react";
import useOpenModal from "lib/hooks/useOpenModal";
import useOutsideClick from "lib/hooks/useOutsideClick";
import { DayPicker } from "react-day-picker";
import moment from "moment";
import { API_URL, put } from "lib/network/api";
import toast from "react-hot-toast";
import AvatarProfile from "./AvatarProfile";
import TitlePage from "components/TitlePage";

const EditProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.Profile);
  const { data } = profileData;
  const { isOpen, openModal, closeModal } = useOpenModal();
  const modalRef = useRef();
  const [preview, setPreview] = useState(null);
  const [pickDate, setPickDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState({
    name: "",
    date_of_birth: "",
    address: "",
    phone_number: "",
    email: "",
  });

  useOutsideClick(() => {
    closeModal();
  }, modalRef);

  useEffect(() => {
    if (data) {
      let result = {
        name: data.name,
        date_of_birth: data.date_of_birth,
        address: data.address,
        phone_number: data.phone_number,
        email: data.email,
      };
      const pickDateData = moment(data.date_of_birth).toDate();
      const formattedDate = pickDateData.toString();
      setPickDate(formattedDate);
      setProfile(result);
      setPreview(data?.profile_picture);
    }
  }, [data]);

  const handleOnChange = (key, value) => {
    let data = {
      ...profile,
    };
    data[key] = value;
    setProfile(data);
  };

  const onPickDate = (params) => {
    setPickDate(params);
    const date = new Date(params);
    const formattedDate = moment(date).toISOString();
    setProfile({
      ...profile,
      date_of_birth: formattedDate,
    });
  };

  const onHandleChange = () => {};

  const onUpdateProfile = () => {
    const payload = {
      name: profile.name,
      date_of_birth: profile.date_of_birth,
      address: profile.address,
      phone_number: profile.phone_number,
    };
    setLoading(true);

    put(`${API_URL}/users/update/profile`, payload)
      .then((res) => {
        toast.success("Profile Update");
        dispatch(fetchProfile());
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Something Went Wrong");
        console.error(err);
      });
  };

  const onGoBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <TitlePage title="Edit Profile" />

      <div className="max-w-2xl mx-auto my-10 flex flex-col gap-5">
        <div className="relative flex w-full h-fit flex-col gap-4 bg-white bg-clip-border p-6 rounded-3xl shadow-xl">
          <AvatarProfile preview={preview} setPreview={setPreview} />
          <Input
            label="Name"
            className="px-3.5 bg-white font-semibold !text-black"
            color="blue"
            size="lg"
            value={profile["name"]}
            onChange={(e) => handleOnChange("name", e.target.value)}
          />
          <div ref={modalRef} className="relative">
            <Input
              label="Date of Birth"
              className="px-3.5 bg-white font-semibold !text-black"
              color="blue"
              size="lg"
              onClick={() => openModal()}
              onChange={(e) => onHandleChange(e.target.value)}
              icon={<IconCalendar className="h-6 w-6 text-gray-500" />}
              value={
                profile["date_of_birth"]
                  ? moment(profile["date_of_birth"]).format("DD/MM/YYYY")
                  : "-"
              }
            />
            {isOpen && (
              <div className="absolute p-2 top-[50px] left-0 rounded-md shadow-md border-gray-300 border bg-white z-50">
                <DayPicker
                  captionLayout="dropdown-buttons"
                  fromYear={1950}
                  toYear={new Date().getFullYear()}
                  mode="single"
                  selected={pickDate}
                  onSelect={(val) => onPickDate(val)}
                  modifiersClassNames={{
                    selected: "!bg-primary !text-white",
                    today: "!text-primary",
                  }}
                />
              </div>
            )}
          </div>
          <Input
            label="Email"
            className="px-3.5 bg-white font-semibold !text-black"
            color="blue"
            size="lg"
            value={profile["email"]}
            onChange={(e) => handleOnChange("email", e.target.value)}
            disabled
          />
          <Input
            label="Phone Number"
            className="px-3.5 bg-white font-semibold !text-black"
            color="blue"
            size="lg"
            value={profile["phone_number"]}
            onChange={(e) => handleOnChange("phone_number", e.target.value)}
          />
          <Input
            label="Address"
            className="px-3.5 bg-white font-semibold !text-black"
            color="blue"
            size="lg"
            value={profile["address"]}
            onChange={(e) => handleOnChange("address", e.target.value)}
          />
          <div className="flex flex-row lg:justify-end gap-4">
            {loading ? (
              <div className="py-2.5 px-6">
                <Spinner color="blue" className="h-[22px] w-[22px]" />
              </div>
            ) : (
              <div className="flex flex-col-reverse w-full lg:flex-row lg:justify-end gap-4">
                <Button
                  onClick={() => onGoBack()}
                  size="md"
                  className="bg-white text-primary border-primary border flex items-center py-2.5 font-poppins font-semibold rounded-md tracking-wide justify-center"
                >
                  BACK
                </Button>
                <Button
                  onClick={() => onUpdateProfile()}
                  size="md"
                  className="bg-primary text-white border-primary border flex items-center py-2.5 font-poppins font-medium rounded-md tracking-wide justify-center"
                >
                  SAVE CHANGES
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
