import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Spinner } from "@material-tailwind/react";
import toast from "react-hot-toast";
import axios from "axios";

import Image from "components/Image";
import InputPassword from "components/InputPassword";
import SideBanner from "./ComponentsPage/SideBanner";
import SideContent from "./ComponentsPage/SideContent";

import { ResetPasswordSchema } from "./SchemaValidation";
import { API_URL } from "lib/network/api";

export default function ConfirmForgotPassword() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values) => {
      handleOnSubmit(values);
    },
  });

  const handleOnSubmit = async (values) => {
    setIsLoading(true);
    const data = {
      password: values.password,
      token: token,
    };

    await axios
      .put(`${API_URL}/users/password`, data)
      .then((res) => {
        setIsLoading(false);
        toast.success("Successfully Setup Password");
        navigate("/login");
      })
      .catch((error) => {
        toast.error(error.detail);
        setIsLoading(false);
      });
  };

  return (
    <div className="flex min-h-full flex-col lg:flex-row">
      <SideBanner />
      <SideContent>
        <div className="w-[182px] relative">
          <Image
            className="relative mx-auto"
            src="/assets/loanly.webp"
            alt="Your Company"
            fill
            priority
          />
        </div>
        <div className="mt-8 flex flex-col gap-4">
          <h3 className="text-2xl font-bold">Setup Password</h3>
          <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </span>
        </div>

        <div className="mt-8">
          <div>
            <form
              className="space-y-4"
              action="#"
              onSubmit={formik.handleSubmit}
            >
              <InputPassword name="password" label="Password" formik={formik} />
              <InputPassword
                name="confirm_password"
                label="Confirm Password"
                formik={formik}
              />
              <div>
                <button
                  disabled={isLoading}
                  type="submit"
                  className="flex w-full relative justify-center rounded-md bg-primary px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-dark"
                >
                  {isLoading ? (
                    <Spinner className="h-6 w-6" color="white" />
                  ) : (
                    "CONFIRM"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </SideContent>
    </div>
  );
}
