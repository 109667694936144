import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@material-tailwind/react";
import CurrencyFormatter from "lib/CurrencyFormatter";

const CardConfirmOffers = ({ item }) => {
  const masterData = useSelector((state) => state.MasterData.data);
  const [feeResult, setFeeResult] = useState(0);

  useEffect(() => {
    const calculationResult = item?.approved_loan_amount - item?.processing_fee;
    setFeeResult(calculationResult);
  }, [item?.approved_loan_amount, item?.processing_fee]);

  const findNameData = (paramsTitle, paramsId) => {
    const resItem = masterData[paramsTitle].find(
      (item) => item.id === paramsId
    );
    return resItem ? resItem.name : "";
  };

  const datas = [
    {
      title: "Approve Loans",
      value: `${
        item?.approved_loan_amount
          ? CurrencyFormatter("en-SG", "SGD", 0).format(
              item?.approved_loan_amount
            )
          : "$0"
      }`,
      per: 6,
    },
    {
      title: "Monthly Cost",
      value: `${
        item?.monthly_cost
          ? CurrencyFormatter("en-SG", "SGD", 0).format(item?.monthly_cost)
          : "$0"
      }`,
      per: 6,
    },
    {
      title: "Monthly Rate",
      value: `${item?.monthly_rate ? `${item?.monthly_rate}%` : "0%"}`,
      per: 6,
    },
  ];

  return (
    <div className="relative flex w-full h-fit flex-col bg-white bg-clip-border p-6 rounded-3xl shadow-xl">
      <div className="flex flex-row gap-5 items-end justify-between mb-4 border-b border-light-gray pb-4">
        {item?.user.profile_picture ? (
          <img
            className="h-12 w-auto mt-4"
            src={item?.user.profile_picture}
            alt=""
          />
        ) : (
          <img className="h-12 w-auto mt-4" src="/assets/loanly.webp" alt="" />
        )}
        <Typography
          variant="h5"
          className="block text-base antialiased font-semibold leading-snug tracking-normal text-blue-gray-900"
        >
          Your confirmed order
        </Typography>
      </div>
      <div className="flex flex-row items-center justify-between gap-4">
        {datas.map((items, idx) => {
          return (
            <div className={`flex flex-col gap-[2px]`} key={idx}>
              <Typography
                variant="small"
                className="text-dark-gray font-medium"
              >
                {items.title}
              </Typography>
              <Typography
                variant="h5"
                className={`${
                  items.title === "Approve Loans"
                    ? "text-gradient-secondary font-extrabold"
                    : "text-black font-extrabold"
                }`}
              >
                {items.value}{" "}
                {items.title === "Monthly Cost" && (
                  <span className="text-xs font-normal text-dark-gray">
                    {item?.tenor_id
                      ? `${findNameData("tenor", item.tenor_id)} Months`
                      : "-"}
                  </span>
                )}
              </Typography>
            </div>
          );
        })}
      </div>
      <div className="relative flex w-full h-fit flex-col gap-2 border-t mt-3 pt-3 border-light-gray">
        <div className="flex flex-row justify-between items-center">
          <Typography variant="small" className="font-semibold text-primary">
            Approved Loan
          </Typography>
          <Typography className="font-semibold text-lg text-black">
            {item?.approved_loan_amount
              ? CurrencyFormatter("en-SG", "SGD", 0).format(
                  item?.approved_loan_amount
                )
              : "$0"}
          </Typography>
        </div>
        <div className="flex flex-row justify-between">
          <Typography variant="small" className="text-dark-gray font-medium">
            Processing Fee (10%)
          </Typography>
          <Typography className="font-semibold text-lg text-black">
            -
            {item?.processing_fee
              ? CurrencyFormatter("en-SG", "SGD", 0).format(
                  item?.processing_fee
                )
              : "$0"}
          </Typography>
        </div>
        <div className="border-b-4 border-light-gray my-2" />
        <div className="flex flex-row justify-between">
          <Typography variant="small" className="font-semibold text-primary">
            You Get
          </Typography>
          <Typography className="font-semibold text-lg text-primary">
            {item?.approved_loan_amount
              ? CurrencyFormatter("en-SG", "SGD", 0).format(feeResult)
              : "$0"}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default CardConfirmOffers;
