import * as Yup from "yup";
const StepFirst = Yup.object().shape({
  loan_amount: Yup.number().required("Please fill field Loan amount"),
  tenor_id: Yup.number().required("Please select Tenor first"),
  main_purpose_id: Yup.number().required("Please select Purpose first"),
  residency_id: Yup.number().required("Please select Residency first"),
  nationality_id: Yup.number().required("Please select Nationality first"),
  employment_status_id: Yup.number().required(
    "Please select Employment status first"
  ),
  monthly_income: Yup.number().required("Please fill field Monthly income"),
});

const Step2 = Yup.object().shape({
  nric_fin: Yup.string()
    .min(9, "NRIC number must be 9 digits")
    .required("Please enter your NRIC/FIN"),
  civil_status_id: Yup.number().required("Please select Civil status first"),
  job_title_id: Yup.number().required("Please select Title Job first"),
  job_industry_id: Yup.number().required("Please select Job Industry first"),
  length_with_current_employer_id: Yup.number().required(
    "Please fill field length with current employer"
  ),
  length_with_previous_employer_id: Yup.number().required(
    "Please fill field length with previous employer"
  ),
  address: Yup.string().required("Please enter your Address first"),
  unit: Yup.string().required("Please enter your Unit first"),
  zip_code: Yup.string().required("Please enter your Zip Code first"),
  housing_type_id: Yup.number().required("Please select House Type first"),
  housing_status_id: Yup.number().required("Please select House Status first"),
  length_of_stay_year_id: Yup.number().required(
    "Please select Length of stay year first"
  ),
  is_own_property: Yup.boolean().required("Please select Own property first"),
});

const Step3 = Yup.object().shape({
  existing_loan_amount_bank: Yup.number().required(
    "Please fill field existing loan amount Bank, if you don't have put $0"
  ),
  existing_loan_amount_moneylender: Yup.number().required(
    "Please fill field existing loan amount Moneylender, if you don't have put $0"
  ),
  monthly_repayment_bank: Yup.number().required(
    "Please fill field Monthly income, if you don't have put $0"
  ),
  monthly_repayment_moneylender: Yup.number().required(
    "Please fill field Monthly income, if you don't have put $0"
  ),
});

export { StepFirst, Step2, Step3 };
