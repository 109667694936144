import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Button,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import CurrencyFormatter from "lib/CurrencyFormatter";

const CardOffers = ({ item, hideChooseBtn }) => {
  const navigate = useNavigate();
  const [showInfo, setShowInfo] = useState(false);
  const [activeTab, setActiveTab] = useState("features");
  const masterData = useSelector((state) => state.MasterData.data);

  const findNameData = (paramsTitle, paramsId) => {
    const resItem = masterData[paramsTitle].find(
      (item) => item.id === paramsId
    );
    return resItem ? resItem.name : "";
  };

  const data = [
    {
      label: "Key Features",
      value: "features",
      desc: `It really matters and then like it really doesn't matter.
      What matters is the people who are sparked by it. And the people 
      who are like offended by it, it doesn't matter.`,
    },
    {
      label: "How it Works",
      value: "works",
      desc: `Because it's about motivating the doers. Because I'm here
      to follow my dreams and inspire other people to follow their dreams, too.`,
    },
  ];
  const datas = [
    {
      title: "Approve Loans",
      value: `${
        item.approved_loan_amount
          ? CurrencyFormatter("en-SG", "SGD", 0).format(
              item.approved_loan_amount
            )
          : "$0"
      }`,
      per: 6,
    },
    {
      title: "Monthly Cost",
      value: `${
        item.monthly_cost
          ? CurrencyFormatter("en-SG", "SGD", 0).format(item.monthly_cost)
          : "$0"
      }`,
      per: 6,
    },
    {
      title: "Monthly Rate",
      value: `${item.monthly_rate ? `${item.monthly_rate}%` : "0%"}`,
      per: 6,
    },
  ];

  const showOpenInfo = () => {
    setShowInfo(!showInfo);
  };

  const goToReviewOffers = () => {
    if (item.status === "PENDING") {
      navigate(`/offers/review-offers/${item.id}`);
    } else {
      navigate(`/offers/review-offers/appointment-detail/${item.id}`);
    }
  };
  console.log(item, "item");

  return (
    <div className="relative flex w-full h-fit flex-col rounded-[26px] bg-white bg-clip-border text-gray-700 shadow-lg break-inside-avoid">
      <div className="px-4 py-3">
        <div className="flex flex-col gap-5 items-center justify-between mb-3 border-b-2 border-light-gray pb-2">
          {item.user.profile_banner ? (
            <img
              className="h-11 w-auto mt-4"
              src={item.user.profile_banner}
              alt="profile user"
            />
          ) : (
            <img
              className="h-11 w-auto mt-4"
              src="/assets/loanly.webp"
              alt="loanly"
            />
          )}
          <Typography
            variant="h5"
            className="block text-base antialiased font-semibold leading-snug tracking-normal text-blue-gray-900"
          >
            Pre-approved by {item.user.name ? item.user.name : "-"}
          </Typography>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {datas.map((items, idx) => {
            return (
              <div className={`flex flex-col gap-[2px]`} key={idx}>
                <Typography variant="small" className="text-dark-gray text-sm">
                  {items.title}
                </Typography>
                <Typography
                  variant="h5"
                  className={`${
                    items.title === "Approve Loans"
                      ? "text-gradient-secondary font-extrabold"
                      : "text-black font-extrabold "
                  } text-[17px]`}
                >
                  {items.value}{" "}
                  {items.title === "Monthly Cost" && (
                    <span className="text-xs font-normal text-dark-gray">
                      {item?.tenor_id
                        ? `${findNameData("tenor", item.tenor_id)} Months`
                        : "-"}
                    </span>
                  )}
                </Typography>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col gap-2 w-full mt-4">
          {!hideChooseBtn && (
            <Button
              onClick={() => goToReviewOffers()}
              size="lg"
              className="bg-primary text-white border-primary border flex items-center py-2.5 font-poppins font-medium rounded-xl tracking-wide justify-center text-sm"
            >
              {item.status === "PENDING" ? "CHOOSE OFFER" : "DETAIL OFFER"}
            </Button>
          )}
          <Button
            onClick={() => showOpenInfo()}
            size="lg"
            className="bg-white text-primary border-primary border flex items-center py-2.5 font-poppins font-semibold rounded-xl tracking-wide justify-center text-sm"
          >
            MORE INFO
          </Button>
        </div>
        {showInfo && (
          <div className="my-4">
            <Tabs value={activeTab}>
              <TabsHeader
                className="rounded-none border-b-4 border-[#F5F5F5] bg-transparent p-0"
                indicatorProps={{
                  className:
                    "bg-transparent border-b-4 border-[#97D1F6] shadow-none rounded-none mt-1",
                }}
              >
                {data.map(({ label, value }) => (
                  <Tab
                    key={value}
                    value={value}
                    onClick={() => setActiveTab(value)}
                    className={
                      activeTab === value ? "text-[#97D1F6]" : "text-dark-gray"
                    }
                  >
                    {label}
                  </Tab>
                ))}
              </TabsHeader>
              <TabsBody>
                {data.map(({ value, desc }) => (
                  <TabPanel key={value} value={value}>
                    {desc}
                  </TabPanel>
                ))}
              </TabsBody>
            </Tabs>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardOffers;
