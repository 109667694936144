import React from "react";
import { Typography } from "@material-tailwind/react";
import { IconBuilding, IconCheck } from "@tabler/icons-react";

const CardTermsCondition = ({ address }) => {
  const data = [
    "NIRC",
    "Singpass Credentials",
    "Employment Contract",
    "Proof of income (3 Month payslip/CPF contribution - if you have just started working, provide your most recent pay slip",
  ];

  return (
    <div className="relative flex flex-col gap-4 bg-white bg-clip-border p-6 rounded-3xl shadow-xl">
      <div className="flex flex-col gap-2 w-full">
        <Typography variant="small" className="font-semibold text-black">
          Address
        </Typography>
        <div className="rounded-xl p-5 flex flex-row gap-4 bg-[#DFF3FC] w-full flex-1">
          <IconBuilding className="text-primary" />
          <span className="font-medium">{address}</span>
        </div>
      </div>
      <div className="flex flex-col gap-2 w-full">
        <Typography variant="small" className="font-semibold text-black">
          Document to bring
        </Typography>
        <ul className="flex flex-col gap-1">
          {data.map((item, idx) => {
            return (
              <li key={idx} className="flex flex-row items-center gap-2">
                <IconCheck className="text-primary w-5 h-5" />
                <span className="text-dark-gray text-sm flex-1">{item}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default CardTermsCondition;
