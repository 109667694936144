import React, { useEffect } from "react";
import { IconUserFilled } from "@tabler/icons-react";
import { Button, Typography } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { storeRemoveAccessToken } from "lib/network/jwt-token-access";
import {
  IconLogout,
  // IconChevronLeft
} from "@tabler/icons-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { signOutRequest } from "store/action/authActions";
import { fetchProfile } from "store/action/profileActions";
import { fetchMasterData } from "store/action/masterDataActions";
import { DataMenu } from "./DataMenu";

const Sidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMenuActive = (menuPath) => {
    const currentPath = location.pathname;
    // Menambahkan pengecekan untuk url yang dimulai dengan "/offers/review-offers"
    if (currentPath.startsWith("/offers/review-offers")) {
      return menuPath === "/";
    }
    return currentPath === menuPath || currentPath.startsWith(menuPath + "/");
  };
  const profileData = useSelector((state) => state.Profile);
  const { data, loading } = profileData;

  useEffect(() => {
    dispatch(fetchProfile());
    dispatch(fetchMasterData());
  }, [dispatch]);

  const logout = () => {
    dispatch(signOutRequest());
    storeRemoveAccessToken();
    navigate("/login");
  };

  return (
    <div className="hidden lg:block w-full h-screen fixed lg:w-72">
      <div className="bg-primary flex flex-col relative pb-6 px-10 min-h-screen">
        <div className="absolute left-0 top-0 z-0">
          <img className="h-full w-auto" src="/assets/sidebar-top.png" alt="" />
        </div>
        <div className="absolute right-0 bottom-0 z-0">
          <img
            className="h-[200px] w-auto"
            src="/assets/sidebar-bottom.png"
            alt=""
          />
        </div>
        <div className="w-full flex flex-col mt-6 justify-center items-center z-10">
          <Link to="/" className="text-center relative ">
            <span className="sr-only">Your Company</span>
            <img
              className="h-11 w-auto"
              src="/assets/loanly_white.webp"
              alt="loanly company"
            />
          </Link>
          {loading ? (
            <div className="w-20 h-20 animate-pulse bg-gray-300 mt-8 rounded-2xl" />
          ) : (
            <>
              {data?.profile_picture ? (
                <img
                  src={data?.profile_picture}
                  alt="avatar"
                  className="mt-8 relative inline-block object-cover object-center w-20 h-20 rounded-3xl"
                />
              ) : (
                <div className="bg-white mt-8 rounded-3xl w-20 h-20 flex justify-center items-center">
                  <IconUserFilled className=" w-16 h-16 text-blue-400" />
                </div>
              )}
            </>
          )}

          <Typography variant="small" className="mt-2 text-white text-base">
            {data?.name ? data?.name : "-"}
          </Typography>
        </div>
        <div className="flex flex-col flex-1 my-10 -mr-10">
          {DataMenu.map((item, idx) => {
            return (
              <div className="relative" key={idx}>
                {isMenuActive(item.url) && (
                  <div className="bg-gray-bg h-[26px] w-[26px] absolute right-0 top-[-26px]">
                    <div className="rounded-br-[26px] bg-primary h-[26px] w-full" />
                  </div>
                )}
                <Link
                  to={item.url}
                  className={`
                ${
                  isMenuActive(item.url)
                    ? "bg-gray-bg text-black-primary font-bold rounded-l-[26px]"
                    : "text-white font-medium"
                } py-4 px-5 flex flex-row items-center gap-3 cursor-pointer text-sm
              `}
                  key={idx}
                >
                  {item.icon}
                  {item.name}
                </Link>
                {isMenuActive(item.url) && (
                  <div className="bg-gray-bg h-[26px] w-[26px] absolute right-0 bottom-[-26px]">
                    <div className="rounded-tr-[26px] bg-primary h-[26px] w-full" />
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="flex flex-col gap-4 w-full relative z-10">
          <Button
            onClick={() => logout()}
            size="lg"
            className="bg-white text-red-500 border-white border flex items-center py-3 font-poppins font-bold rounded-2xl tracking-wide justify-center -mx-[10px]"
          >
            <IconLogout />
            Log Out
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
