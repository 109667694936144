import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IconChevronLeft } from "@tabler/icons-react";
import {
  Typography,
  Button,
  Dialog,
  DialogHeader,
  DialogFooter,
  Spinner,
} from "@material-tailwind/react";
import { API_URL, get, patch } from "lib/network/api";
import CardTermsCondition from "../ComponentsPage/CardTermsCondition";
import CardConfirmOffers from "../ComponentsPage/CardConfirmOffers";
import toast from "react-hot-toast";
import ComponentSetDate from "../ComponentsPage/ComponentSetDate";
import ComponentSetTime from "../ComponentsPage/ComponentSetTime";
import moment from "moment";
import TitlePage from "components/TitlePage";

const BookAppointment = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [data, setData] = useState(null);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [error, setError] = useState({
    date: false,
    time: false,
  });
  const [payload, setPayload] = useState({
    status: "ACCEPTED",
    appointment_date: null,
    appointment_time: "",
  });

  useEffect(() => {
    const fetchGetDetailLoan = () => {
      setLoading(true);
      get(`${API_URL}/loan/offerings/${id}`)
        .then((res) => {
          setData(res.data);
          setTimeout(() => {
            setLoading(false);
          }, 1500);
        })
        .catch((err) => {
          toast.error("Something Went Wrong");
          setLoading(false);
        });
    };

    fetchGetDetailLoan();
  }, [id]);

  const goBack = () => {
    navigate(-1);
  };

  const handleOpen = () => {
    let newError = { ...error };

    if (!payload.appointment_date) {
      newError = {
        ...newError,
        date: true,
      };
    } else {
      newError = {
        ...newError,
        date: false,
      };
    }

    if (!payload.appointment_time) {
      newError = {
        ...newError,
        time: true,
      };
    } else {
      newError = {
        ...newError,
        time: false,
      };
    }

    setError(newError);

    if (!newError.date && !newError.time) {
      setShowModalConfirmation(!showModalConfirmation);
    }
  };

  const onConfirm = () => {
    setLoadingConfirm(true);
    const data = payload;
    patch(`${API_URL}/loan/offerings/${id}`, data)
      .then((res) => {
        toast.success("Successfully Confirm Appointment");
        setTimeout(() => {
          setLoadingConfirm(false);
          navigate(`/offers/review-offers/appointment-detail/${id}`);
        }, 1000);
      })
      .catch((error) => {
        toast.error(error.detail.error);
        setLoadingConfirm(false);
        setShowModalConfirmation(false);
      });
  };

  if (loading) {
    return (
      <div>
        <TitlePage
          title="Book Appointment"
          subtitle={`Select your appointment time slot below and the necessary informantion for you visit. For final approval you will need to visit ${
            data?.user.name ? `${data.user.name} office` : "-"
          }`}
        />
        <div className="max-w-xl mx-auto my-10 flex flex-col gap-5 animate-pulse">
          <Typography
            as="div"
            variant="h1"
            className="h-[298px] rounded-3xl bg-gray-300"
          >
            &nbsp;
          </Typography>
          <Typography
            as="div"
            variant="h1"
            className="h-[100px] rounded-3xl bg-gray-300"
          >
            &nbsp;
          </Typography>
          <Typography
            as="div"
            variant="h1"
            className="h-[348px] rounded-3xl bg-gray-300"
          >
            &nbsp;
          </Typography>
          <div className="flex flex-col gap-4 w-full">
            <Button
              size="md"
              className="bg-primary text-white border-primary border flex items-center py-3.5 font-poppins font-medium rounded-2xl tracking-wide justify-center"
            >
              BOOK APPOINTMENT
            </Button>
            <Button
              onClick={() => goBack()}
              size="md"
              className="bg-white text-primary border-primary border flex items-center py-3 font-poppins font-semibold rounded-2xl tracking-wide justify-center"
            >
              <IconChevronLeft />
              BACK TO REVIEW OFFER
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <TitlePage
        title="Book Appointment"
        subtitle={`Select your appointment time slot below and the necessary informantion for you visit. For final approval you will need to visit ${
          data?.user.name ? `${data.user.name} office` : "-"
        }`}
      />

      <div className="max-w-xl mx-auto my-10 flex flex-col gap-5">
        <CardTermsCondition
          address={data?.user.address ? `${data.user.address}` : "-"}
        />
        <div className="p-6 bg-white rounded-3xl flex flex-row justify-between gap-3 shadow-xl">
          <ComponentSetDate
            payload={payload}
            setPayload={setPayload}
            error={error}
            setError={setError}
          />
          <ComponentSetTime
            payload={payload}
            setPayload={setPayload}
            error={error}
            setError={setError}
          />
        </div>
        <CardConfirmOffers item={data} />
        <div className="flex flex-col gap-4 w-full">
          <Button
            onClick={() => handleOpen()}
            size="md"
            className="bg-primary text-white border-primary border flex items-center py-3.5 font-poppins font-medium rounded-2xl tracking-wide justify-center"
          >
            BOOK APPOINTMENT
          </Button>
          <Button
            onClick={() => goBack()}
            size="md"
            className="bg-white text-primary border-primary border flex items-center py-3 font-poppins font-semibold rounded-2xl tracking-wide justify-center"
          >
            <IconChevronLeft />
            BACK TO REVIEW OFFER
          </Button>
        </div>
        <Dialog
          open={showModalConfirmation}
          handler={handleOpen}
          size="sm"
          className="p-2 lg:p-7 rounded-2xl"
        >
          <DialogHeader>
            <div className="relative w-full items-center flex flex-col justify-center">
              <Typography className="mt-6 text-[30px] text-center font-normal mx-auto text-primary">
                Confirm Appointment?
              </Typography>
              <Typography
                variant="small"
                className="mt-6 text-center font-medium mx-auto"
              >
                Please confirm your appointment on{" "}
                {payload.appointment_date
                  ? moment(payload.appointment_date).format("ll")
                  : "-"}{" "}
                at {payload.appointment_time ? payload.appointment_time : "-"}.
              </Typography>
            </div>
          </DialogHeader>
          <DialogFooter className="flex flex-col gap-4">
            {loadingConfirm ? (
              <div className="flex flex-col justify-center mt-5 gap-4 w-full">
                <Spinner className="h-10 w-10 mx-auto" color="blue" />
              </div>
            ) : (
              <>
                <Button
                  className="bg-primary font-normal w-full py-3.5 tracking-wider text-sm rounded-2xl opacity-1 shadow-gray-900/50"
                  onClick={onConfirm}
                >
                  CONFIRM
                </Button>
                <Button
                  className="text-primary border-2 border-primary bg-white font-medium w-full py-3.5 tracking-wider text-sm rounded-2xl"
                  onClick={handleOpen}
                >
                  NO, GO BACK
                </Button>
              </>
            )}
          </DialogFooter>
        </Dialog>
      </div>
    </div>
  );
};

export default BookAppointment;
