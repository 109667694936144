import { useNavigate } from "react-router-dom";
import SectionForm from "./SectionForm";
import { Button } from "@material-tailwind/react";
import { IconChevronLeft } from "@tabler/icons-react";
import TitlePage from "components/TitlePage";
import WithAuthentication from "lib/WithAuth";

function Application() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <TitlePage
        title="Create New Application"
        subtitle="Create new application for offering new amount"
      />

      <div className="max-w-3xl mx-auto   mt-16">
        <Button
          onClick={() => goBack()}
          size="md"
          className="bg-primary text-white flex items-center py-2 font-poppins font-semibold rounded-2xl tracking-wide justify-center mb-4"
        >
          <IconChevronLeft />
          BACK
        </Button>
        <div className="bg-white rounded-xl py-8 px-6 shadow-lg">
          <SectionForm />
        </div>
      </div>
    </div>
  );
}

export default WithAuthentication(Application);
