import React, { useState } from "react";
import { Spinner } from "@material-tailwind/react";
import { IconArrowsLeftRight } from "@tabler/icons-react";
import { uploadFormData } from "lib/network/api";
import toast from "react-hot-toast";

const UploadDocument = ({ apiUrl, title, name, id, data, onUploadFetch }) => {
  const previewData = data;
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (
      file &&
      ["image/jpeg", "image/png", "application/pdf"].includes(file.type)
    ) {
      setSelectedFile(file);
      setError(null);
    } else {
      setError("Invalid file type. Please select a PNG, JPG, or PDF file.");
    }
  };

  const handleUpload = () => {
    if (!selectedFile) {
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append(id, selectedFile);

    uploadFormData(apiUrl, formData)
      .then((response) => {
        setSelectedFile(null);
        toast.success(`Upload File Success ${name}`);
        setTimeout(() => {
          setLoading(false);
          onUploadFetch();
        }, 1000);
      })
      .catch((error) => {
        console.error(error);
        toast.error(`Something went wrong`);
        setLoading(false);
      });
  };

  const getFileNameFromUrl = (url) => {
    const parts = url.split("/");
    return parts[parts.length - 1];
  };

  const onDownloadFile = () => {
    if (!previewData) {
      return;
    }

    const fileName = getFileNameFromUrl(data);

    const link = document.createElement("a");
    link.href = data;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (previewData === "") {
    return (
      <div key={id}>
        <div className="flex flex-col lg:flex-row justify-between gap-4 items-end lg:items-center">
          <div
            className={`py-3 px-4 w-full flex flex-col flex-1 border-2 rounded-xl ${
              selectedFile
                ? "text-primary cursor-pointer border-primary select-none font-semibold"
                : "text-netral border-netral"
            }`}
            onClick={() => onDownloadFile()}
            type="button"
          >
            {selectedFile
              ? selectedFile?.name
                ? selectedFile?.name
                : selectedFile
              : title}
          </div>
          {selectedFile ? (
            <div className="flex flex-row gap-2">
              {loading ? (
                <div className="w-[136px] flex justify-center">
                  <Spinner color="blue" />
                </div>
              ) : (
                <div className="flex flex-row gap-2">
                  <button
                    onClick={handleUpload}
                    className="bg-white text-primary border-primary border flex items-center py-3.5 px-2 font-poppins font-medium rounded-xl tracking-wide justify-center"
                  >
                    <span className="text-xs font-medium">UPLOAD</span>
                  </button>
                  <label
                    htmlFor={id}
                    className={`cursor-pointer bg-primary text-white border-primary border flex items-center py-3.5 px-2 font-poppins font-medium rounded-xl tracking-wide justify-center`}
                  >
                    <span className="text-xs font-medium flex gap-1">
                      <IconArrowsLeftRight className="w-4 h-4 text-white" />{" "}
                      FILE
                    </span>
                    <input
                      id={id}
                      type="file"
                      className="hidden"
                      onChange={handleFileChange}
                      accept=".jpg,.jpeg,.png,.pdf"
                    />
                  </label>
                </div>
              )}
            </div>
          ) : (
            <label
              htmlFor={id}
              className={`cursor-pointer bg-primary text-white border-primary border flex items-center py-3.5 px-6 font-poppins font-medium rounded-xl tracking-wide justify-center`}
            >
              <span className="text-xs font-medium">BROWSER FILE</span>
              <input
                id={id}
                type="file"
                className="hidden"
                onChange={handleFileChange}
                accept=".jpg,.jpeg,.png,.pdf"
              />
            </label>
          )}
        </div>
        {error && <span className="text-xs text-red-500">{error}</span>}
      </div>
    );
  }

  return (
    <div key={id}>
      <div className="flex flex-col lg:flex-row justify-between gap-4 items-end lg:items-center">
        <div
          className={`py-3 px-4 w-full flex flex-col flex-1 border-2 rounded-xl ${
            previewData
              ? "text-primary cursor-pointer border-primary select-none font-semibold"
              : "text-netral border-netral"
          }`}
          onClick={() => onDownloadFile()}
          type="button"
        >
          {previewData
            ? title
            : selectedFile
            ? selectedFile?.name
              ? selectedFile?.name
              : selectedFile
            : title}
        </div>
        {selectedFile ? (
          <div className="flex flex-row gap-2">
            {loading ? (
              <div className="w-[136px] flex justify-center">
                <Spinner color="blue" />
              </div>
            ) : (
              <div className="flex flex-row gap-2">
                <button
                  onClick={handleUpload}
                  className="bg-white text-primary border-primary border flex items-center py-3.5 px-2 font-poppins font-medium rounded-xl tracking-wide justify-center"
                >
                  <span className="text-xs font-medium">UPLOAD</span>
                </button>
                <label
                  htmlFor={id}
                  className={`cursor-pointer bg-primary text-white border-primary border flex items-center py-3.5 px-2 font-poppins font-medium rounded-xl tracking-wide justify-center`}
                >
                  <span className="text-xs font-medium flex gap-1">
                    <IconArrowsLeftRight className="w-4 h-4 text-white" /> FILE
                  </span>
                  <input
                    id={id}
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                    accept=".jpg,.jpeg,.png,.pdf"
                  />
                </label>
              </div>
            )}
          </div>
        ) : (
          <label
            htmlFor={id}
            className={`cursor-pointer bg-primary text-white border-primary border flex items-center py-3.5 px-6 font-poppins font-medium rounded-xl tracking-wide justify-center`}
          >
            <span className="text-xs font-medium">BROWSER FILE</span>
            <input
              id={id}
              type="file"
              className="hidden"
              onChange={handleFileChange}
              accept=".jpg,.jpeg,.png,.pdf"
            />
          </label>
        )}
      </div>
      {error && <span className="text-xs text-red-500">{error}</span>}
    </div>
  );
};

export default UploadDocument;
