import React from "react";
import { Input } from "@material-tailwind/react";

const InputComponent = ({ name, label, formik, placeholder, size }) => {
  const { touched, errors } = formik || {};
  const field = formik.getFieldProps(name);

  return (
    <div>
      <Input
        label={label}
        className="px-3.5 bg-white font-semibold !text-black"
        color="blue"
        size={size}
        placeholder={placeholder}
        onChange={(e) => {
          formik.handleChange(field.name)(e);
        }}
        error={errors[field.name] && touched[field.name]}
        value={field.value}
      />
      {errors[field.name] && touched[field.name] && (
        <div className="mt-1 text-sm text-red-500">{errors[field.name]}</div>
      )}
    </div>
  );
};

export default InputComponent;
