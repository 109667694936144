import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Button,
  Dialog,
  DialogHeader,
  DialogFooter,
  Spinner,
} from "@material-tailwind/react";
import { API_URL, get, patch } from "lib/network/api";
import CardTermsCondition from "../ComponentsPage/CardTermsCondition";
import CardConfirmOffers from "../ComponentsPage/CardConfirmOffers";
import toast from "react-hot-toast";
import TitlePage from "components/TitlePage";

const AppointmentDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [data, setData] = useState(null);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);

  useEffect(() => {
    const fetchGetDetailLoan = () => {
      setLoading(true);
      get(`${API_URL}/loan/offerings/${id}`)
        .then((res) => {
          setData(res.data);

          setTimeout(() => {
            setLoading(false);
          }, 1500);
        })
        .catch((err) => {
          toast.error("Something Went Wrong");
          setLoading(false);
        });
    };

    fetchGetDetailLoan();
  }, [id]);

  const goOffers = () => {
    navigate("/");
  };

  const handleOpen = () => {
    setShowModalConfirmation(!showModalConfirmation);
  };

  const onConfirm = () => {
    setLoadingConfirm(true);
    const data = {
      status: "CANCELED",
    };
    patch(`${API_URL}/loan/offerings/${id}`, data)
      .then((res) => {
        setLoadingConfirm(false);
        setShowModalConfirmation(false);
        toast.success("Cancel Appointment Success");
        navigate("/my-application");
      })
      .catch((error) => {
        toast.error(error.detail.error);
        setLoadingConfirm(false);
      });
  };

  if (loading) {
    return (
      <div>
        <TitlePage
          title="Appointment Booked!"
          subtitle="Your appointment is booked and all you have to do now is to visit
        the lender’s office for final approval. If approved, you will
        receive the money on the spot."
        />
        <div className="max-w-xl mx-auto my-10 flex flex-col gap-5 animate-pulse">
          <Typography
            as="div"
            variant="h1"
            className="h-[298px] rounded-3xl bg-gray-300"
          >
            &nbsp;
          </Typography>
          <Typography
            as="div"
            variant="h1"
            className="h-[348px] rounded-3xl bg-gray-300"
          >
            &nbsp;
          </Typography>
          <div className="flex flex-row gap-4 w-full">
            <Button
              onClick={() => goOffers()}
              size="md"
              className="bg-gray-bg text-primary border-primary border-2 flex flex-1 items-center py-3.5 font-poppins font-medium rounded-2xl tracking-wide justify-center"
            >
              Offers
            </Button>
            <Button
              size="md"
              className="bg-gray-bg text-red-500 border-red-500 border-2 flex flex-1 items-center py-3 font-poppins font-semibold rounded-2xl tracking-wide justify-center"
            >
              CANCEL APPOINTMENT
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="pb-16">
      <TitlePage
        title="Appointment Booked!"
        subtitle="Your appointment is booked and all you have to do now is to visit
        the lender’s office for final approval. If approved, you will
        receive the money on the spot."
      />

      <div className="max-w-xl mx-auto my-10 flex flex-col gap-5">
        <CardTermsCondition
          address={data?.user.address ? `${data.user.address}` : "-"}
        />
        <CardConfirmOffers item={data} />
        <div className="flex flex-row gap-4 w-full">
          <Button
            onClick={() => goOffers()}
            size="md"
            className="bg-gray-bg text-primary border-primary border-2 flex flex-1 items-center py-3.5 font-poppins font-medium rounded-2xl tracking-wide justify-center"
          >
            Offers
          </Button>
          <Button
            onClick={() => handleOpen()}
            size="md"
            className="bg-gray-bg text-red-500 border-red-500 border-2 flex flex-1 items-center py-3 font-poppins font-semibold rounded-2xl tracking-wide justify-center"
          >
            CANCEL APPOINTMENT
          </Button>
        </div>
        <Dialog
          open={showModalConfirmation}
          handler={handleOpen}
          size="sm"
          className="p-2 lg:p-7 rounded-2xl"
        >
          <DialogHeader>
            <div className="relative w-full items-center flex flex-col justify-center">
              <Typography className="mt-6 text-[30px] text-center font-normal mx-auto text-red-500">
                Cancel Appointment?
              </Typography>
              <Typography
                variant="small"
                className="mt-6 text-center font-medium mx-auto"
              >
                Your appointment will be cancel, and you can book another
                appointment.
              </Typography>
            </div>
          </DialogHeader>
          <DialogFooter className="flex flex-col gap-4">
            {loadingConfirm ? (
              <div className="flex flex-col justify-center mt-5 gap-4 w-full">
                <Spinner className="h-10 w-10 mx-auto" color="blue" />
              </div>
            ) : (
              <>
                <Button
                  className="bg-red-500 font-normal w-full py-3.5 tracking-wider text-sm rounded-2xl opacity-1 shadow-gray-900/50"
                  onClick={onConfirm}
                >
                  CONFIRM
                </Button>
                <Button
                  className="text-primary border-2 border-primary bg-white font-medium w-full py-3.5 tracking-wider text-sm rounded-2xl"
                  onClick={handleOpen}
                >
                  NO, GO BACK
                </Button>
              </>
            )}
          </DialogFooter>
        </Dialog>
      </div>
    </div>
  );
};

export default AppointmentDetail;
