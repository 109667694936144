import React, { useState, useEffect } from "react";
import { Spinner } from "@material-tailwind/react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchMasterData } from "store/action/masterDataActions";
import { fetchProfile } from "store/action/profileActions";
import SectionStep from "./ComponentsPage/SectionStep";
import FirstStep from "./StepForm/Step1";
import SecondStep from "./StepForm/Step2";
import ThridStep from "./StepForm/Step3";

const SectionForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramsPage = searchParams.get("path");
  const paramsSection = searchParams.get("section");
  const [step, setStep] = useState(paramsSection ? parseInt(paramsSection) : 0);
  const [stepRender, setStepRender] = useState(
    paramsPage ? paramsPage : "step1"
  );
  const masterData = useSelector((state) => state.MasterData);
  const formApplication = useSelector((state) => state.FormApplication);
  const [data, setData] = useState(formApplication);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchMasterData())
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    dispatch(fetchProfile());
  }, [dispatch]);

  const DataRender = {
    step1: (
      <FirstStep
        step={step}
        setStep={setStep}
        setStepRender={setStepRender}
        masterData={masterData}
        data={data}
        setData={setData}
      />
    ),
    step2: (
      <SecondStep
        step={step}
        setStep={setStep}
        setStepRender={setStepRender}
        masterData={masterData}
        data={data}
        setData={setData}
      />
    ),
    step3: (
      <ThridStep
        step={step}
        setStep={setStep}
        setStepRender={setStepRender}
        masterData={masterData}
        data={data}
        setData={setData}
      />
    ),
  };

  if (loading) {
    return (
      <div className="col-span-12 lg:col-span-2 px-2 lg:px-6">
        <div className="flex flex-col justify-center mt-5 gap-4 w-full">
          <Spinner className="h-10 w-10 mx-auto" color="blue" />
        </div>
      </div>
    );
  }

  return (
    <div className="col-span-12 lg:col-span-2 px-2 lg:px-6">
      <SectionStep
        step={step}
        setStep={setStep}
        setStepRender={setStepRender}
      />
      <div className="my-9">{DataRender[stepRender]}</div>
    </div>
  );
};

export default SectionForm;
