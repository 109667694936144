import React from "react";
import { Button, Select, Option } from "@material-tailwind/react";
import { Formik } from "formik";
import { IconChevronRight } from "@tabler/icons-react";
import { StepFirst } from "./SchemaValidation";
import InputNumberComponent from "components/InputNumberComponent";
import InputSelectComponent from "components/InputSelectComponent";
import SectionButtonStep from "../ComponentsPage/SectionButtonStep";
import SectionHeader from "../ComponentsPage/SectionHeader";

const FirstStep = ({
  step,
  setStep,
  setStepRender,
  masterData,
  data,
  setData,
}) => {
  const handleOnNext = async (values) => {
    setData({
      ...data,
      loan_amount: values.loan_amount,
      tenor_id: parseInt(values.tenor_id),
      main_purpose_id: parseInt(values.main_purpose_id),
      residency_id: parseInt(values.residency_id),
      nationality_id: parseInt(values.nationality_id),
      employment_status_id: parseInt(values.employment_status_id),
      monthly_income: values.monthly_income,
    });
    setStepRender("step2");
    setStep(step + 50);
  };

  const onChangeSelect = (setFieldValue, name, value) => {
    if (name === "residency_id") {
      setFieldValue("residency_id", value);
      if (value === "1" || value === "2") {
        setFieldValue("residency_id", value);
        setFieldValue("nationality_id", "1");
      }
    }

    setFieldValue(name, value);
  };

  return (
    <div>
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            loan_amount: data.loan_amount ? data.loan_amount : 20000,
            tenor_id: data.tenor_id ? data.tenor_id : 4,
            main_purpose_id: data.main_purpose_id,
            residency_id: data.residency_id
              ? data.residency_id.toString()
              : null,
            nationality_id: data.nationality_id
              ? data.nationality_id.toString()
              : null,
            employment_status_id: data.employment_status_id,
            monthly_income: data.monthly_income,
          }}
          validationSchema={StepFirst}
          onSubmit={async (values) => {
            handleOnNext(values);
          }}
        >
          {({ handleSubmit, setFieldValue, values, touched, errors }) => (
            <form className="space-y-8" onSubmit={handleSubmit}>
              <div>
                <SectionHeader title={"Loan Details"} />
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
                  <InputNumberComponent
                    name="loan_amount"
                    label="Loan Amount"
                    setFieldValue={setFieldValue}
                  />
                  <InputSelectComponent
                    name="tenor_id"
                    option={masterData.data.tenor}
                    label="Tenure (Month)"
                    setFieldValue={setFieldValue}
                  />
                  <InputSelectComponent
                    name="main_purpose_id"
                    option={masterData.data.purposes}
                    label="Main Purpose"
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
              <div>
                <SectionHeader title={"General Information"} />
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
                  <div>
                    <Select
                      label="Residency Status"
                      color="blue"
                      className="bg-white font-semibold !text-black px-3.5"
                      size="lg"
                      value={values.residency_id}
                      onChange={(val) =>
                        onChangeSelect(setFieldValue, "residency_id", val)
                      }
                      error={Boolean(
                        touched["residency_id"] && errors["residency_id"]
                      )}
                      menuProps={{ className: "max-h-[200px]" }}
                    >
                      {masterData.data.residencies?.length ? (
                        masterData.data.residencies.map((item, idx) => {
                          return (
                            <Option key={idx} value={`${item.id}`}>
                              {item.name}
                            </Option>
                          );
                        })
                      ) : (
                        <Option>NO DATA</Option>
                      )}
                    </Select>
                    {touched["residency_id"] && errors["residency_id"] && (
                      <div className="text-red-600 mt-1 text-sm">
                        {errors["residency_id"]}
                      </div>
                    )}
                  </div>
                  <div>
                    <Select
                      label="Nationality"
                      color="blue"
                      className="bg-white font-semibold !text-black px-3.5"
                      size="lg"
                      value={
                        values.residency_id === "1" ||
                        values.residency_id === "2"
                          ? "1"
                          : values.nationality_id
                      }
                      onChange={(val) =>
                        onChangeSelect(setFieldValue, "nationality_id", val)
                      }
                      error={Boolean(
                        touched["nationality_id"] && errors["nationality_id"]
                      )}
                      menuProps={{ className: "max-h-[200px]" }}
                    >
                      {masterData.data.nationalities?.length ? (
                        masterData.data.nationalities.map((item, idx) => {
                          return (
                            <Option key={idx} value={`${item.id}`}>
                              {item.name}
                            </Option>
                          );
                        })
                      ) : (
                        <Option>NO DATA</Option>
                      )}
                    </Select>
                    {touched["nationality_id"] && errors["nationality_id"] && (
                      <div className="text-red-600 mt-1 text-sm">
                        {errors["nationality_id"]}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <SectionHeader title={"Income Details"} />
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
                  <InputSelectComponent
                    name="employment_status_id"
                    option={masterData.data["employment-statuses"]}
                    label="Employment Status"
                    setFieldValue={setFieldValue}
                  />
                  <InputNumberComponent
                    name="monthly_income"
                    label="Monthly Income"
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
              <SectionButtonStep
                step={step}
                onGoNext={
                  <Button
                    type="submit"
                    size="md"
                    className="bg-primary text-white flex items-center pl-8 pr-6 py-2 font-poppins font-medium rounded-xl tracking-wide"
                  >
                    Next
                    <IconChevronRight />
                  </Button>
                }
              />
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default FirstStep;
