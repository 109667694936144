import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  Typography,
  Button,
  Dialog,
  DialogHeader,
  DialogFooter,
  Spinner,
  Alert,
} from "@material-tailwind/react";
import { API_URL, put } from "lib/network/api";
import useOpenModal from "lib/hooks/useOpenModal";
import InputPassword from "components/InputPassword";
import { storeRemoveAccessToken } from "lib/network/jwt-token-access";
import { signOutRequest } from "store/action/authActions";
import * as Yup from "yup";
import { IconAlertTriangle } from "@tabler/icons-react";
import TitlePage from "components/TitlePage";

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isOpen, openModal, closeModal } = useOpenModal();
  const [isLoading, setIsLoading] = useState(false);
  const [payload, setPayload] = useState(null);
  const [error, setError] = useState({
    show: false,
    title: "",
  });

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object().shape({
      old_password: Yup.string()
        .min(8)
        .max(40, "Password have a maximum of digits")
        .required("Please enter your current password"),
      new_password: Yup.string()
        .min(8)
        .max(40, "Password have a maximum of digits")
        .required("Please enter your new password")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^=};{?.,:/@$'"+:)(<>!%*#?&])[A-Za-z\d^}={;?.,'"/:@+:$!<>)(%*#?&]{8,}$/,
          "Must contain minimum eight characters, at least one letter, one number and one special character"
        ),
      confirm_password: Yup.string()
        .min(8)
        .oneOf([Yup.ref("new_password")], "Your new password do not match")
        .required("Confirm password is required"),
    }),
    onSubmit: async (values) => {
      openModalChange(values);
    },
  });

  const onGoBack = () => {
    navigate(-1);
  };

  const openModalChange = (values) => {
    const payloadRes = {
      old_password: values.old_password,
      new_password: values.new_password,
    };
    setPayload(payloadRes);
    openModal();
  };

  const handleOnSubmit = () => {
    setIsLoading(true);
    put(`${API_URL}/users/change-password`, payload)
      .then((res) => {
        setIsLoading(false);
        dispatch(signOutRequest());
        storeRemoveAccessToken();
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      })
      .catch((err) => {
        setIsLoading(false);
        const { detail } = err;
        setError({
          show: true,
          title: detail.error,
        });
      });
  };

  return (
    <div>
      <TitlePage title="Change Password" />

      <div className="max-w-2xl mx-auto my-10 flex flex-col gap-5">
        <div className="relative flex w-full h-fit flex-col gap-4 bg-white bg-clip-border p-6 rounded-3xl shadow-xl">
          <form className="space-y-4" action="#" onSubmit={formik.handleSubmit}>
            <InputPassword
              name="old_password"
              label="Old Password"
              formik={formik}
            />
            <InputPassword
              name="new_password"
              label="New Password"
              formik={formik}
            />
            <InputPassword
              name="confirm_password"
              label="Confirm Password"
              formik={formik}
            />
            <div className="flex flex-col-reverse w-full lg:flex-row lg:justify-end gap-4">
              <Button
                onClick={() => onGoBack()}
                size="md"
                className="bg-white text-primary border-primary border flex items-center py-2.5 font-poppins font-semibold rounded-md tracking-wide justify-center"
              >
                BACK
              </Button>
              <Button
                type="submit"
                size="md"
                className="bg-primary text-white border-primary border flex items-center py-2.5 font-poppins font-medium rounded-md tracking-wide justify-center"
              >
                SAVE CHANGES
              </Button>
            </div>
          </form>
        </div>
      </div>
      <Dialog
        open={isOpen}
        handler={openModal}
        size="sm"
        className="p-2 lg:p-7 rounded-2xl"
        dismiss={{
          outsidePress: false,
        }}
      >
        <DialogHeader>
          <div className="relative w-full items-center flex flex-col justify-center">
            <Typography className="mt-6 text-[30px] text-center font-normal max-w-[280px] mx-auto text-primary">
              Are you sure want change password?
            </Typography>
            <Typography
              variant="small"
              className="mt-6 text-center font-medium mx-auto w-[350px]"
            >
              After successfully changing the password, you will be redirected
              to the Login page to re-login
            </Typography>
          </div>
        </DialogHeader>
        <DialogFooter>
          {isLoading ? (
            <div className="flex flex-col justify-center mt-5 gap-4 w-full">
              <Spinner className="h-10 w-10 mx-auto" color="blue" />
            </div>
          ) : (
            <div className="flex flex-col gap-4 w-full">
              <Button
                className="bg-primary font-normal w-full py-3 lg:py-3.5 tracking-wider text-sm rounded-2xl shadow-gray-900/50"
                onClick={handleOnSubmit}
              >
                Change Password
              </Button>
              <Button
                className="text-primary border-2 border-primary bg-white font-medium w-full py-3 lg:py-3.5 tracking-wider text-sm rounded-2xl"
                onClick={closeModal}
              >
                Cancel
              </Button>
            </div>
          )}
          <Alert
            className="mt-3 py-2 px-3 text-sm lg:text-base lg:p-6 flex flex-row items-center"
            variant="gradient"
            open={error.show}
            icon={<IconAlertTriangle />}
            color="red"
            action={
              <Button
                variant="text"
                color="white"
                size="sm"
                className="ml-auto"
                onClick={() =>
                  setError({
                    show: false,
                    title: "",
                  })
                }
              >
                Close
              </Button>
            }
          >
            {error.title}
          </Alert>
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default ChangePassword;
