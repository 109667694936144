import React from "react";
import Image from "components/Image";

const SideBanner = () => {
  return (
    <div className="relative lg:block w-full h-full basis-1/2">
      <div className="block lg:hidden">
        <div className="relative h-full z-0">
          <Image
            className="w-full h-[200px] object-cover"
            src="/assets/auth-image-full.png"
            alt="bg-company"
            priority
            fill
          />
        </div>
      </div>
      <div className="h-screen w-full relative hidden lg:block">
        <div className="relative h-full">
          <Image
            className="w-full h-screen object-cover"
            src="/assets/auth-image.png"
            alt="bg-company"
            priority
            fill
          />
        </div>
      </div>
    </div>
  );
};

export default SideBanner;
