import React, { useState } from "react";
import { Formik } from "formik";
import { Button, Spinner } from "@material-tailwind/react";
import { IconChevronRight } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { Step3 } from "./SchemaValidation";
import SectionButtonStep from "../ComponentsPage/SectionButtonStep";
import { Input, Select, Option } from "@material-tailwind/react";
import FormRadio from "../ComponentsPage/FormRadio";
import SectionHeader from "../ComponentsPage/SectionHeader";
import InputNumberComponent from "components/InputNumberComponent";
import { useDispatch } from "react-redux";
import { setFormDataApplication } from "store/action/formApplicationActions";

const ThridStep = ({
  step,
  setStep,
  setStepRender,
  masterData,
  data,
  setData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState();

  const handleOnNext = (values) => {
    setLoading(true);
    const payload = {
      ...data,
      is_have_existing_loan: values.is_have_existing_loan,
      existing_loan_amount_bank: values.existing_loan_amount_bank,
      existing_loan_amount_moneylender: values.existing_loan_amount_moneylender,
      total_existing_loan_amount:
        values.existing_loan_amount_bank +
        values.existing_loan_amount_moneylender,
      monthly_repayment_bank: values.monthly_repayment_bank,
      monthly_repayment_moneylender: values.monthly_repayment_moneylender,
      total_monthly_loan_repayment:
        values.monthly_repayment_bank + values.monthly_repayment_moneylender,
      is_agency_related: values.is_agency_related,
    };

    dispatch(setFormDataApplication(payload));
    setTimeout(() => {
      setLoading(false);
      navigate("/my-application/create-new-application/details");
    }, 1000);
  };

  const handleOnPrev = () => {
    setStepRender("step2");
    setStep(step - 50);
  };

  const onChangeSelect = (setFieldValue, name, values) => {
    const result = values === "yes" ? true : false;
    setFieldValue(name, result);
  };

  const findTotalExisting = (params1, params2) => {
    const data1 = params1 ? params1 : 0;
    const data2 = params2 ? params2 : 0;
    const result = data1 + data2;
    return `$${result}`;
  };

  return (
    <div>
      <SectionHeader title={"Your Financial Commitments"} />
      <div className="mt-8">
        <Formik
          initialValues={{
            is_have_existing_loan: data.is_have_existing_loan,
            existing_loan_amount_bank: data.existing_loan_amount_bank,
            existing_loan_amount_moneylender:
              data.existing_loan_amount_moneylender,
            total_existing_loan_amount: data.total_existing_loan_amount,
            monthly_repayment_bank: data.monthly_repayment_bank,
            monthly_repayment_moneylender: data.monthly_repayment_moneylender,
            total_monthly_loan_repayment: data.total_monthly_loan_repayment,
            is_agency_related: data.is_agency_related,
          }}
          validationSchema={Step3}
          onSubmit={async (values) => {
            handleOnNext(values);
          }}
        >
          {({ handleSubmit, values, setFieldValue, touched, errors }) => (
            <form className="space-y-6" action="#" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                <div>
                  <Select
                    label="Do You Have Any Existing Loans?"
                    color="blue"
                    className="bg-white font-semibold !text-black px-3.5"
                    size="lg"
                    value={values.is_have_existing_loan ? "yes" : "no"}
                    onChange={(val) =>
                      onChangeSelect(
                        setFieldValue,
                        "is_have_existing_loan",
                        val
                      )
                    }
                    error={Boolean(
                      touched["is_have_existing_loan"] &&
                        errors["is_have_existing_loan"]
                    )}
                  >
                    <Option value="yes">Yes</Option>
                    <Option value="no">No</Option>
                  </Select>
                  {touched["is_have_existing_loan"] &&
                    errors["is_have_existing_loan"] && (
                      <div className="text-red-600 mt-1 text-sm">
                        {errors["is_have_existing_loan"]}
                      </div>
                    )}
                </div>
                <div>
                  <InputNumberComponent
                    name="existing_loan_amount_moneylender"
                    label="Existing Loan Amount(Moneylenders) in $"
                    setFieldValue={setFieldValue}
                    type="handle-zero"
                  />
                </div>
                <div>
                  <InputNumberComponent
                    name="existing_loan_amount_bank"
                    label="Existing Loan Amount(Banks/CC) in $"
                    setFieldValue={setFieldValue}
                    type="handle-zero"
                  />
                </div>
                <div>
                  <Input
                    label="Total Existing Loan Amount in $"
                    className="px-3.5 bg-white font-semibold !text-black"
                    color="blue"
                    size="lg"
                    placeholder="Total existing Loan Amount(Banks/CC)"
                    disabled
                    value={
                      values.total_existing_loan_amount
                        ? values.total_existing_loan_amount
                        : findTotalExisting(
                            values.existing_loan_amount_moneylender,
                            values.existing_loan_amount_bank
                          )
                    }
                  />
                  <span className="mt-2 text-sm block text-netral">
                    Calculation: Existing loan amount(Banks and credit Cards) +
                    Existing loan amount(Moneylenders).
                  </span>
                </div>
              </div>
              <div className="!mt-20" />
              <SectionHeader title={"Your Current Monthly Repayment"} />
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                <div>
                  <InputNumberComponent
                    name="monthly_repayment_bank"
                    label="Monthly Repayment(Banks/CC) in $"
                    setFieldValue={setFieldValue}
                    type="handle-zero"
                  />
                </div>
                <div>
                  <InputNumberComponent
                    name="monthly_repayment_moneylender"
                    label="Monthly Repayment(Moneylenders) in $"
                    setFieldValue={setFieldValue}
                    type="handle-zero"
                  />
                </div>
                <div>
                  <Input
                    label="Total Monthly Loan Repayment in $"
                    className="px-3.5 bg-white font-semibold !text-black"
                    color="blue"
                    size="lg"
                    placeholder="Total Monthly Loan Repayment in $"
                    disabled
                    value={
                      values.total_monthly_loan_repayment
                        ? values.total_monthly_loan_repayment
                        : findTotalExisting(
                            values.monthly_repayment_bank,
                            values.monthly_repayment_moneylender
                          )
                    }
                  />
                </div>
              </div>
              <span className="block !mt-10 !-mb-2 text-sm">
                Are you talking to an agency or intermediary regarding
                bankruptcy or debt restructuring service?
              </span>
              <FormRadio
                name="is_agency_related"
                setFieldValue={setFieldValue}
                options={[
                  { name: "Yes", value: true },
                  { name: "No", value: false },
                ]}
              />
              {loading ? (
                <div>
                  <div className="mt-16 flex flex-col justify-center gap-4 w-full">
                    <Spinner className="h-10 w-10 mx-auto" color="blue" />
                  </div>
                </div>
              ) : (
                <SectionButtonStep
                  step={step}
                  onGoNext={
                    <Button
                      type="submit"
                      size="md"
                      className="bg-primary text-white flex items-center pl-8 pr-6 py-2 font-poppins font-medium rounded-xl tracking-wide"
                    >
                      Next
                      <IconChevronRight />
                    </Button>
                  }
                  onGoPrev={handleOnPrev}
                />
              )}
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ThridStep;
