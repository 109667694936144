import React, { useEffect, useState, useCallback } from "react";
import { API_URL, get } from "lib/network/api";
import { Spinner } from "@material-tailwind/react";
import CardApplication from "./ComponentsPage/CardApplication";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import LoadingMyApplication from "./loading";
import NoDataApplication from "./nodata";
import TitlePage from "components/TitlePage";
import toast from "react-hot-toast";

const MyApplication = () => {
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingLoadMore, setLoadingLoadMore] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [status, setStatus] = useState(undefined);

  const FetchMyApplication = () => {
    const resStatus =
      status !== undefined ? status : `PENDING,APPROVED,EXPIRED`;
    setLoading(true);
    get(`${API_URL}/loan/applications?page=1&page_size=9&status=${resStatus}`)
      .then((res) => {
        setData(res.data);
        setStatus(resStatus);
        setPage(1);
        const resLastPage =
          res.metadata && Object.keys(res.metadata).length > 0
            ? res.metadata.last_page
            : 1;
        setLastPage(resLastPage);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
        setLoading(false);
      });
  };

  useEffect(() => {
    FetchMyApplication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const onLoadMore = useCallback(() => {
    if (page === lastPage || loadingLoadMore) {
      return;
    }

    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (scrollTop + clientHeight === scrollHeight) {
      setLoadingLoadMore(true);
      const nextPage = page + 1;
      get(
        `${API_URL}/loan/applications?page=${nextPage}&page_size=9&status=${status}`
      )
        .then((res) => {
          setData((prevItems) => [...prevItems, ...res.data]);
          setPage(nextPage);
          const resLastPage =
            res.metadata && Object.keys(res.metadata).length > 0
              ? res.metadata.last_page
              : 1;
          setLastPage(resLastPage);
          setTimeout(() => {
            setLoadingLoadMore(false);
          }, 1500);
        })
        .catch((err) => {
          setLoadingLoadMore(false);
        });
    }
  }, [loadingLoadMore, page, lastPage, status]);

  useEffect(() => {
    window.addEventListener("scroll", onLoadMore);
    return () => {
      window.removeEventListener("scroll", onLoadMore);
    };
  }, [onLoadMore]);

  // const goToCreateApplication = () => {
  //   navigate("/my-application/create-new-application");
  // };

  // const onChangeStatus = (params) => {
  //   setStatus(params);
  // };

  const RenderList = () => {
    if (loading) {
      return <LoadingMyApplication />;
    }

    if (Boolean(!data.length)) {
      return <NoDataApplication />;
    }

    return (
      <div className="mt-6">
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 300: 1, 500: 1, 700: 2, 900: 3 }}
        >
          <Masonry gutter="20px">
            {data?.map((i, idx) => {
              return (
                <CardApplication key={idx} item={i} index={idx} hideChooseBtn />
              );
            })}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    );
  };

  return (
    <div className="pb-20">
      <TitlePage
        title="My Application"
        subtitle="The offers below are pre-approved by each lender. To Proceed, choose the offer that suits you best"
      />
      <div className="grid grid-cols-2 lg:grid-cols-3 items-center gap-4 mt-16">
        {/* <Select
          label="Filter By Status"
          color="blue"
          className="bg-white font-semibold !text-black px-3.5"
          size="lg"
          value={status !== "PENDING,APPROVED,EXPIRED" ? status : undefined}
          onChange={(val) => onChangeStatus(val)}
          containerProps={{
            className: "!min-w-[100px] lg:!min-w-[200px]",
          }}
        >
          <Option value="PENDING">Pending</Option>
          <Option value="APPROVED">Approved</Option>
          <Option value="EXPIRED">Expired</Option>
        </Select>
        <Select
          label="Sort By"
          color="blue"
          className="bg-white font-semibold !text-black px-3.5"
          size="lg"
          containerProps={{
            className: "!min-w-[100px] lg:!min-w-[200px]",
          }}
        >
          <Option>Date</Option>
          <Option>Name</Option>
        </Select> */}
        {/* {Boolean(!data.length) && (
          <Button
            onClick={() => goToCreateApplication()}
            size="md"
            className="bg-primary text-white border-primary border flex items-center py-3 lg:py-2.5 font-poppins font-medium rounded-md tracking-wide justify-center col-span-2 lg:col-span-1 w-full lg:max-w-[200px]"
          >
            Create Application
          </Button>
        )} */}
      </div>
      <RenderList />
      <div className="h-[150px] py-10 flex justify-center items-center">
        {loadingLoadMore && <Spinner color="blue" size="lg" />}
      </div>
    </div>
  );
};

export default MyApplication;
