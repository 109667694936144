import React from "react";
import { Typography } from "@material-tailwind/react";

const LoadingMyApplication = () => {
  return (
    <div>
      <div className="grid grid-cols-3 animate-pulse my-6 gap-5">
        {[...Array(6)].map((item, idx) => {
          return (
            <Typography
              key={idx}
              as="div"
              variant="h1"
              className="mb-4 h-[270px] rounded-[26px] bg-gray-300"
            >
              &nbsp;
            </Typography>
          );
        })}
      </div>
    </div>
  );
};

export default LoadingMyApplication;
