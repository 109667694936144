import React, { useState, useEffect, useCallback } from "react";
import { API_URL, get } from "lib/network/api";
// import { Select, Option } from "@material-tailwind/react";
import CardOffers from "./ComponentsPage/CardOffers";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import LoadingMyOffers from "./loading";
import NoDataOffers from "./nodata";
import TitlePage from "components/TitlePage";
import toast from "react-hot-toast";

const MyOffers = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(undefined);
  const [lastPage, setLastPage] = useState(null);
  const [loadingLoadMore, setLoadingLoadMore] = useState(false);

  const FetchOffers = () => {
    const resStatus = status !== undefined ? status : `offerings-pending`;
    setLoading(true);
    get(`${API_URL}/loan-applications/0/offerings?page=1&page_size=9`)
      .then((res) => {
        setData(res.data);
        setStatus(resStatus);
        setPage(1);
        const resLastPage =
          res.metadata && Object.keys(res.metadata).length > 0
            ? res.metadata.last_page
            : 1;
        setLastPage(resLastPage);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
        setLoading(false);
      });
  };

  useEffect(() => {
    FetchOffers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const onLoadMore = useCallback(() => {
    if (page === lastPage || loadingLoadMore) {
      return;
    }

    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (scrollTop + clientHeight === scrollHeight) {
      setLoadingLoadMore(true);
      const nextPage = page + 1;
      get(
        `${API_URL}/loan-applications/0/offerings?page=${nextPage}&page_size=9`
      )
        .then((res) => {
          setData((prevItems) => [...prevItems, ...res.data]);
          setPage(nextPage);
          const resLastPage =
            res.metadata && Object.keys(res.metadata).length > 0
              ? res.metadata.last_page
              : 1;
          setLastPage(resLastPage);
          setTimeout(() => {
            setLoadingLoadMore(false);
          }, 1500);
        })
        .catch((err) => {
          setLoadingLoadMore(false);
        });
    }
  }, [loadingLoadMore, page, lastPage]);

  useEffect(() => {
    window.addEventListener("scroll", onLoadMore);
    return () => {
      window.removeEventListener("scroll", onLoadMore);
    };
  }, [onLoadMore]);

  // const onChangeStatus = (params) => {
  //   setStatus(params);
  // };

  const RenderList = () => {
    if (loading) {
      return <LoadingMyOffers />;
    }

    if (Boolean(!data.length)) {
      return <NoDataOffers />;
    }

    return (
      <div className="mt-6">
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 300: 1, 500: 1, 700: 2, 900: 3 }}
        >
          <Masonry gutter="20px">
            {data?.map((i, idx) => {
              return <CardOffers key={idx} item={i} />;
            })}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    );
  };

  return (
    <div className="pb-20">
      <TitlePage
        title="Offers"
        subtitle="The offers below are pre-approved by each lender. To Proceed, choose the offer that suits you best"
      />
      {/* <div className="grid grid-cols-2 lg:grid-cols-3 items-center gap-4 mt-16">
        <Select
          label="Filter By Status"
          color="blue"
          className="bg-white font-semibold !text-black px-3.5"
          containerProps={{
            className: "!min-w-[100px] lg:min-w-[200px]",
          }}
          size="lg"
          value={status}
          onChange={(val) => onChangeStatus(val)}
        >
          <Option value="offerings-pending">Pending</Option>
          <Option value="offerings-accepted">Accepted</Option>
        </Select>
        <Select
          label="Sort By"
          color="blue"
          className="bg-white font-semibold !text-black px-3.5"
          size="lg"
          containerProps={{
            className: "!min-w-[100px] lg:min-w-[200px]",
          }}
        >
          <Option>Date</Option>
          <Option>Name</Option>
        </Select>
      </div> */}
      <RenderList />
    </div>
  );
};

export default MyOffers;
