import { Typography } from "@material-tailwind/react";

const TitlePage = ({ title, subtitle }) => {
  return (
    <div className="text-center">
      <Typography variant="h1" className="font-normal text-3xl lg:text-3xl">
        {title}
      </Typography>
      {subtitle && (
        <Typography className="mt-3 font-normal max-w-[380px] mx-auto">
          {subtitle}
        </Typography>
      )}
    </div>
  );
};

export default TitlePage;
