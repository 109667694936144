import React from "react";
import { Typography } from "@material-tailwind/react";
import CurrencyFormatter from "lib/CurrencyFormatter";

const CardCalculation = ({ item }) => {
  const calculationResult = (paramsAmount, paramsFee) => {
    const result = paramsAmount - paramsFee;
    return `${CurrencyFormatter("en-SG", "SGD", 0).format(result)}`;
  };

  return (
    <div className="relative flex w-full h-fit flex-col gap-2 bg-white bg-clip-border p-6 rounded-3xl">
      <div className="flex flex-row justify-between items-center">
        <Typography variant="small" className="font-semibold text-primary">
          Approved Loan
        </Typography>
        <Typography className="font-black text-lg text-black">
          {item?.approved_loan_amount
            ? CurrencyFormatter("en-SG", "SGD", 0).format(
                item?.approved_loan_amount
              )
            : "$0"}
        </Typography>
      </div>
      <div className="flex flex-row justify-between">
        <Typography variant="small" className="text-dark-gray font-medium">
          Processing Fee (10%)
        </Typography>
        <Typography className="font-black text-lg text-black">
          -
          {item?.processing_fee
            ? CurrencyFormatter("en-SG", "SGD", 0).format(item?.processing_fee)
            : "$0"}
        </Typography>
      </div>
      <div className="border-b-2 border-light-gray my-2" />
      <div className="flex flex-row justify-between">
        <Typography variant="small" className="font-semibold text-primary">
          You Get
        </Typography>
        <Typography className="font-semibold text-lg text-primary">
          {item?.approved_loan_amount
            ? calculationResult(
                item?.approved_loan_amount,
                item?.processing_fee
              )
            : "$0"}
        </Typography>
      </div>
    </div>
  );
};

export default CardCalculation;
