import React, { useState, useRef } from "react";
import { API_URL, putUploadFile } from "lib/network/api";
import { Button, Spinner } from "@material-tailwind/react";
import { useDispatch } from "react-redux";
import { fetchProfile } from "store/action/profileActions";
import { IconPencil, IconUserFilled } from "@tabler/icons-react";
import toast from "react-hot-toast";

const AvatarProfile = ({ preview, setPreview }) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
    const resultPreview = e.target.files[0]
      ? URL.createObjectURL(e.target.files[0])
      : null;
    setPreview(resultPreview);
  };

  const handleEditClick = () => {
    fileInputRef.current.click();
  };

  const onUploadImage = () => {
    setLoading(true);
    putUploadFile(`${API_URL}/users/update/picture`, file, "profile_picture")
      .then((res) => {
        setLoading(false);
        toast.success("Profile Picture has been update");
        setFile(null);
        dispatch(fetchProfile());
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
        setLoading(false);
        console.error(err);
      });
  };

  return (
    <div className="flex flex-col justify-center items-center my-5">
      <div className="relative">
        {preview ? (
          <img
            src={preview}
            alt="avatar"
            className="relative inline-block object-cover object-center w-24 h-24 rounded-lg z-5"
          />
        ) : (
          <div className="bg-gray-100 relative rounded-lg z-10 w-24 h-24 flex justify-center items-center shadow-xl">
            <IconUserFilled className=" w-16 h-16 text-blue-400" />
          </div>
        )}
        <div
          className=" absolute bottom-[-10px] right-[-10px] z-10"
          onClick={handleEditClick}
        >
          <div className="bg-gray-bg w-7 h-7 rounded-md flex justify-center items-center border border-white cursor-pointer">
            <IconPencil className="w-5 h-5 text-primary" />
            <input
              ref={fileInputRef}
              className="sr-only"
              id="file"
              type="file"
              onChange={handleFileChange}
            />
          </div>
        </div>
      </div>
      {file && (
        <div className="mt-4">
          <Button
            className="bg-primary"
            size="sm"
            onClick={() => onUploadImage()}
          >
            {loading ? (
              <Spinner color="white" className="h-4 w-4" />
            ) : (
              "Upload Image"
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

export default AvatarProfile;
