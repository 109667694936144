import React, { useState, useEffect } from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { JWT_ACCESS_TOKEN_KEY } from 'lib/network/jwt-token-access';
import FullLayout from "layout/FullLayout";
import { Progress } from "@material-tailwind/react";

const AppRoute = () => {
    const token = localStorage.getItem(JWT_ACCESS_TOKEN_KEY);
    const location = useLocation();
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const simulateLoading = () => {
            setIsLoading(true);

            const interval = setInterval(() => {
                setLoadingProgress((prevProgress) => {
                    if (prevProgress >= 100) {
                        clearInterval(interval);
                        setIsLoading(false);
                        return 0;
                    }
                    return prevProgress + 1;
                });
            }, 30);

            setTimeout(() => {
                clearInterval(interval);
                setIsLoading(false);
            }, 500);
        };

        simulateLoading();
    }, [location]);

    return (
        <>{isLoading && (
            <Progress
                variant="gradient"
                color="blue"
                value={loadingProgress}
                className="top-0 left-0 w-full"
                style={{ zIndex: 0, position: 'absolute' }}
                size="sm"
            />
        )}
            {token ? (
                <FullLayout>
                    <Outlet />
                </FullLayout>
            ) : (
                <Navigate to="/login" />
            )}
        </>
    );
};

export default AppRoute;
