import React from "react";
import Sidebar from "./Sidebar";
import HeaderPage from "./Header";

const FullLayout = ({ children }) => {
  return (
    <div className="min-h-screen w-full flex">
      <Sidebar />
      <div className="lg:pl-72 w-full">
        <div className="block lg:hidden bg-primary">
          <HeaderPage />
        </div>
        <div className="flex flex-col flex-grow bg-gray-bg flex-1 rounded-t-2xl -mt-3 lg:mt-0">
          <div className="container mx-auto py-10">
            <div className="min-h-screen px-4 sm:px-6 lg:px-8 max-w-[1088px] mx-auto">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullLayout;
