import React, { useState } from "react";
import { Input } from "@material-tailwind/react";
import { IconEye, IconEyeOff } from "@tabler/icons-react";

const InputPassword = ({ name, label, formik, placeholder, size }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { touched, errors } = formik || {};
  const field = formik.getFieldProps(name);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div>
      <div className="relative">
        <Input
          type={showPassword ? "text" : "password"}
          label={label}
          className="pl-3.5 pr-9 bg-white font-semibold !text-black"
          color="blue"
          onChange={(e) => {
            formik.handleChange(field.name)(e);
          }}
          onBlur={(e) => {
            formik.handleBlur(field.name)(e);
          }}
          value={field.value}
          containerProps={{
            className: `${size ? size : "min-w-0"}`,
          }}
          placeholder={placeholder}
          error={errors[field.name] && touched[field.name]}
        />
        <div
          className="absolute right-2 top-2 cursor-pointer"
          onClick={handleTogglePasswordVisibility}
        >
          {showPassword ? <IconEyeOff /> : <IconEye />}
        </div>
      </div>
      {errors[field.name] && touched[field.name] && (
        <div className="mt-1 text-sm text-red-500">{errors[field.name]}</div>
      )}
    </div>
  );
};

export default InputPassword;
