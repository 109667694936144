import { useState } from "react";
import { useFormik } from "formik";
import { Spinner } from "@material-tailwind/react";
import toast from "react-hot-toast";

import Image from "components/Image";
import InputComponent from "components/InputComponent";
import SideBanner from "./ComponentsPage/SideBanner";
import SideContent from "./ComponentsPage/SideContent";

import { ForgotPasswordSchema } from "./SchemaValidation";
import { post, API_URL } from "lib/network/api";

export default function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState({
    show: false,
    message: "Check your email for forgot password",
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values) => {
      handleOnSubmit(values);
    },
  });

  const handleOnSubmit = async (values) => {
    setIsLoading(true);
    const data = {
      email: values.email,
    };

    await post(`${API_URL}/tokens/password-reset`, data)
      .then((res) => {
        setIsLoading(false);
        toast.success("Successfully send email for forgot password");
        setSuccess({
          ...success,
          show: true,
        });
      })
      .catch((error) => {
        toast.error(error.detail);
        setIsLoading(false);
      });
  };

  return (
    <div className="flex min-h-full flex-col lg:flex-row">
      <SideBanner />
      <SideContent>
        <div className="w-[182px] relative">
          <Image
            className="relative mx-auto"
            src="/assets/loanly.webp"
            alt="Your Company"
            fill
            priority
          />
        </div>
        <div className="mt-8 flex flex-col gap-4">
          <h3 className="text-2xl font-bold">Forgot Password</h3>
          <span>
            {success.show
              ? success.message
              : "Lorem ipsum dolor sit amet, consectetur adipiscing elit."}
          </span>
        </div>

        <div className="mt-8">
          <div>
            <form
              className="space-y-4"
              action="#"
              onSubmit={formik.handleSubmit}
            >
              <InputComponent
                name="email"
                type="text"
                label="Email Address"
                formik={formik}
              />
              <div>
                <button
                  disabled={isLoading}
                  type="submit"
                  className="flex w-full relative justify-center rounded-md bg-primary px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-dark"
                >
                  {isLoading ? (
                    <Spinner className="h-6 w-6" color="white" />
                  ) : (
                    "SEND"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </SideContent>
    </div>
  );
}
