import React from "react";
import { Typography, Button } from "@material-tailwind/react";
import Image from "components/Image";
import { useNavigate } from "react-router-dom";

const NoDataApplication = () => {
  const navigate = useNavigate();

  const goToCreateApplication = () => {
    navigate("/my-application/create-new-application");
  };

  return (
    <div className="mt-16">
      <div className="flex flex-col justify-center items-center gap-4">
        <div className="w-[220px] h-[220px]">
          <Image
            className="relative mx-auto"
            src="/assets/nodata.svg"
            alt="no-data"
            fill
            priority
          />
        </div>
        <Typography className="text-center text-base">
          You Don't Have Application,
          <br /> Create New Application
        </Typography>
        <Button
          onClick={() => goToCreateApplication()}
          size="md"
          className="mt-5 bg-primary text-white border-primary border flex items-center py-3 lg:py-2.5 font-poppins font-medium rounded-md tracking-wide justify-center col-span-2 lg:col-span-1 w-full lg:max-w-[200px]"
        >
          Create Application
        </Button>
      </div>
    </div>
  );
};

export default NoDataApplication;
