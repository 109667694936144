import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Button,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import {
  IconClockFilled,
  IconCircleCheckFilled,
  IconAlertCircleFilled,
} from "@tabler/icons-react";
import CurrencyFormatter from "lib/CurrencyFormatter";

const CardApplication = ({ item }) => {
  const [showInfo, setShowInfo] = useState(false);
  const [activeTab, setActiveTab] = useState("features");
  const masterData = useSelector((state) => state.MasterData.data);

  const findNameData = (paramsTitle, paramsId) => {
    const resItem = masterData[paramsTitle].find(
      (item) => item.id === paramsId
    );
    return resItem ? resItem.name : "";
  };

  const capitalize = (str) => {
    const lower = str.toLowerCase();
    const capital = str.charAt(0).toUpperCase();
    return capital + lower.slice(1);
  };

  const data = [
    {
      label: "Key Features",
      value: "features",
      desc: `It really matters and then like it really doesn't matter.
      What matters is the people who are sparked by it. And the people 
      who are like offended by it, it doesn't matter.`,
    },
    {
      label: "How it Works",
      value: "works",
      desc: `Because it's about motivating the doers. Because I'm here
      to follow my dreams and inspire other people to follow their dreams, too.`,
    },
  ];

  const datas = [
    {
      title: "Full Name",
      value: `${item.full_name ? item.full_name : "-"}`,
      per: 6,
    },
    {
      title: "Loans Amount",
      value: `${
        item.loan_amount
          ? CurrencyFormatter("en-SG", "SGD", 0).format(item.loan_amount)
          : "$0"
      }`,
      per: 6,
    },
    {
      title: "Tenor",
      value: `${
        item?.tenor_id ? `${findNameData("tenor", item?.tenor_id)}` : "-"
      }`,
      per: 6,
    },
    {
      title: "Status",
      value: `${item?.status ? `${capitalize(item.status)}` : "-"}`,
      per: 6,
    },
  ];

  const findIcon = (params) => {
    switch (params.toLowerCase()) {
      case "pending":
        return <IconClockFilled className="w-7 h-7 text-amber-400" />;
      case "approved":
        return <IconCircleCheckFilled className="w-7 h-7 text-green-400" />;
      case "expired":
        return <IconAlertCircleFilled className="w-7 h-7 text-red-400" />;
      default:
        return;
    }
  };

  const showOpenInfo = () => {
    setShowInfo(!showInfo);
  };

  return (
    <div className="relative flex w-full h-fit flex-col gap-3 rounded-[26px] bg-white bg-clip-border text-gray-700 shadow-lg break-inside-avoid p-4">
      <div className="flex flex-row gap-5 items-center justify-between border-b-2 border-light-gray pb-2">
        <div className="flex flex-col">
          <span className="text-xs font-normal">Purpose</span>
          <span className="text-xl font-bold text-black">
            {item?.main_purpose_id
              ? findNameData("purposes", item?.main_purpose_id)
              : "-"}
          </span>
        </div>
        {item.status && findIcon(item.status)}
      </div>
      <div className="grid grid-cols-2 gap-4">
        {datas.map((item, idx) => {
          return (
            <div
              className={`flex flex-col gap-[2px] ${
                item.title === "Address" ? "col-span-2" : ""
              }`}
              key={idx}
            >
              <Typography variant="small" className="text-dark-gray text-sm ">
                {item.title}
              </Typography>
              <Typography
                variant="h5"
                className={`${
                  item.title === "Loans Amount"
                    ? "text-gradient-secondary font-extrabold"
                    : "text-black font-extrabold"
                } text-lg truncate w-[120px]`}
              >
                {item.value}{" "}
                {item.title === "Tenor" && (
                  <span className="font-light text-sm">Month</span>
                )}
              </Typography>
            </div>
          );
        })}
      </div>
      <div className="w-full justify-end mt-4">
        <Button
          onClick={() => showOpenInfo()}
          size="lg"
          className="bg-white text-primary border-primary border flex items-center py-2.5 font-poppins font-semibold rounded-xl tracking-wide justify-center w-full"
        >
          MORE INFO
        </Button>
      </div>
      {showInfo && (
        <div className="my-4">
          <Tabs value={activeTab}>
            <TabsHeader
              className="rounded-none border-b-4 border-[#F5F5F5] bg-transparent p-0"
              indicatorProps={{
                className:
                  "bg-transparent border-b-4 border-[#97D1F6] shadow-none rounded-none mt-1",
              }}
            >
              {data.map(({ label, value }) => (
                <Tab
                  key={value}
                  value={value}
                  onClick={() => setActiveTab(value)}
                  className={
                    activeTab === value ? "text-[#97D1F6]" : "text-dark-gray"
                  }
                >
                  {label}
                </Tab>
              ))}
            </TabsHeader>
            <TabsBody>
              {data.map(({ value, desc }) => (
                <TabPanel key={value} value={value}>
                  {desc}
                </TabPanel>
              ))}
            </TabsBody>
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default CardApplication;
