import React, { useState, useRef } from "react";
import { IconCalendar } from "@tabler/icons-react";
import useOutsideClick from "lib/hooks/useOutsideClick";
import { DayPicker } from "react-day-picker";
import moment from "moment";

const ComponentSetDate = ({ payload, setPayload, error, setError }) => {
  const modalRef = useRef();
  const [showDate, setShowDate] = useState(false);
  const [convertDate, setConvertDate] = useState(null);
  const [pickDate, setPickDate] = useState(null);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  useOutsideClick(() => {
    setShowDate(false);
  }, modalRef);

  const onPickDate = (params) => {
    const TimeStamp = moment(params).valueOf();
    const convertParams = moment(params).format("DD/MM/YYYY");
    setConvertDate(convertParams);
    setPayload({
      ...payload,
      appointment_date: TimeStamp,
    });
    setPickDate(params);
    setShowDate(false);
    setError({
      ...error,
      date: false,
    });
  };

  return (
    <div className="relative w-full" ref={modalRef}>
      <div
        className="py-3 px-4 flex flex-row justify-between items-center border-2 rounded-xl cursor-pointer"
        onClick={() => setShowDate(true)}
      >
        <span className="font-medium text-base">
          {convertDate ? convertDate : "Select Date"}
        </span>
        <IconCalendar />
      </div>
      {error.date && (
        <span className="text-sm mt-1 text-red-500 block">
          Plase select date first
        </span>
      )}
      {showDate && (
        <div className="absolute top-[60px] left-0 rounded-md shadow-md border-gray-300 border bg-white z-50">
          <DayPicker
            captionLayout="dropdown-buttons"
            mode="single"
            selected={pickDate}
            disabled={{ from: new Date(2000, 1, 1), to: yesterday }}
            onSelect={(val) => onPickDate(val)}
            modifiersClassNames={{
              selected: "!bg-primary !text-white",
              today: "!text-primary",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ComponentSetDate;
