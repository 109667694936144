import React from "react";

const SideContent = ({ children }) => {
  return (
    <div className="bg-[#F5F7FA] flex basis-1/2 flex-col justify-center -mt-10 lg:mt-0 rounded-t-[40px] lg:rounded-none h-screen relative z-10">
      <div className="mx-auto w-full lg:w-72 px-[30px] py-12 lg:px-2">
        {children}
      </div>
    </div>
  );
};

export default SideContent;
