import React from "react";
// import { Navigate } from 'react-router-dom';

// PUBLIC
import Login from "pages/public/Authentication/Login";
// import Signup from 'pages/public/Authentication/Signup';
import ForgotPassword from "pages/public/Authentication/Forgotpassword";
import ConfirmForgotPassword from "pages/public/Authentication/Confrimforgotpassword";

// PRIVATE
import MyOffers from "pages/private/MyOffers";
import ReviewOffers from "pages/private/MyOffers/ReviewOffers";
import BookAppointment from "pages/private/MyOffers/BookAppointment";
import AppointmentDetail from "pages/private/MyOffers/AppointmentDetail";
import DocumentUpload from "pages/private/DocumentUpload";
import MyApplication from "pages/private/MyApplication";
import CreateNewApplication from "pages/private/CreateNewApplication";
import DetailsApplication from "pages/private/CreateNewApplication/Detail";
import Profile from "pages/private/Profile";
import ChangePassword from "pages/private/Profile/ChangePassword";
import EditProfile from "pages/private/Profile/EditProfile";

const authProtectedRoutes = [
  { path: "/", element: <MyOffers /> },
  { path: "/offers/review-offers/:id", element: <ReviewOffers /> },
  {
    path: "/offers/review-offers/book-appointment/:id",
    element: <BookAppointment />,
  },
  {
    path: "/offers/review-offers/appointment-detail/:id",
    element: <AppointmentDetail />,
  },
  { path: "/document-upload", element: <DocumentUpload /> },
  { path: "/my-application", element: <MyApplication /> },
  {
    path: "/my-application/create-new-application",
    element: <CreateNewApplication />,
  },
  {
    path: "/my-application/create-new-application/details",
    element: <DetailsApplication />,
  },
  { path: "/profile", element: <Profile /> },
  { path: "/profile/edit-profile", element: <EditProfile /> },
  { path: "/profile/change-password", element: <ChangePassword /> },
];

const publicRoutes = [
  { path: "/login", element: <Login /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/reset-password/:token", element: <ConfirmForgotPassword /> },
];

export { authProtectedRoutes, publicRoutes };
