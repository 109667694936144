import React, { useState, useEffect } from "react";
import { Button } from "@material-tailwind/react";
import { IconChevronRight } from "@tabler/icons-react";
import { Formik } from "formik";
import { Select, Option, Input } from "@material-tailwind/react";
import { Step2 } from "./SchemaValidation";
import SectionButtonStep from "../ComponentsPage/SectionButtonStep";
import SectionHeader from "../ComponentsPage/SectionHeader";
import InputSelectComponent from "components/InputSelectComponent";
import InputComponentCostume from "components/InputComponentCostume";
import axios from "axios";
import { API_URL } from "lib/network/api";

const SecondStep = ({
  step,
  setStep,
  setStepRender,
  masterData,
  data,
  setData,
}) => {
  const [error, setError] = useState(false);
  const [addressData, setAddressData] = useState("");
  const [isAddressChanged, setIsAddressChanged] = useState(false);

  useEffect(() => {
    if (data.address) {
      setIsAddressChanged(true);
    }
  }, [data]);

  const handleOnNext = (values) => {
    if (error) {
      return;
    }

    setData({
      ...data,
      nric_fin: values.nric_fin,
      civil_status_id: parseInt(values.civil_status_id),
      job_title_id: parseInt(values.job_title_id),
      job_industry_id: parseInt(values.job_industry_id),
      length_with_current_employer_id: parseInt(
        values.length_with_current_employer_id
      ),
      length_with_previous_employer_id: parseInt(
        values.length_with_previous_employer_id
      ),
      address: values.address,
      unit: values.unit,
      zip_code: values.zip_code,
      housing_type_id: parseInt(values.housing_type_id),
      housing_status_id: parseInt(values.housing_status_id),
      length_of_stay_year_id: parseInt(values.length_of_stay_year_id),
      is_own_property: values.is_own_property,
    });
    setStepRender("step3");
    setStep(step + 50);
  };

  const FindAddress = (values, setFieldValue) => {
    if (values.length < 6) {
      return;
    }

    axios
      .get(
        `https://www.onemap.gov.sg/api/common/elastic/search?searchVal=${values}&returnGeom=N&getAddrDetails=Y&pageNum=1`
      )
      .then((res) => {
        setIsAddressChanged(false);
        const { data } = res;
        const resultAddress = data.results;
        if (resultAddress.length) {
          setAddressData(
            resultAddress[0].BLK_NO + " " + resultAddress[0].ROAD_NAME
          );
          setFieldValue(
            "address",
            resultAddress[0].BLK_NO + " " + resultAddress[0].ROAD_NAME
          );
        } else {
          setAddressData("");
          setFieldValue("address", "");
        }
      })
      .catch((error) => {
        setAddressData("");
        setFieldValue("address", "");
      });
  };

  const handleOnPrev = () => {
    setStepRender("step1");
    setStep(step - 50);
  };

  const vallidateNRIC = (values) => {
    if (values.length < 9) {
      return;
    }

    const payload = {
      nric: values,
    };

    axios
      .post(`${API_URL}/loan/validate-nric`, payload)
      .then((res) => {
        const { data } = res;
        if (data.data) {
          setError(false);
        } else {
          setError(true);
        }
      })
      .catch((error) => {
        setError(true);
      });
  };

  const onChangeNRIC = (setFieldValue, name, value) => {
    setError(false);
    setFieldValue(name, value);
  };

  const onChangeSelect = (setFieldValue, name, values) => {
    const result = values === "yes" ? true : false;
    setFieldValue(name, result);
  };

  const onChangePostalCode = (setFieldValue, name, value) => {
    setFieldValue(name, value);
  };

  return (
    <div>
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            nric_fin: data.nric_fin,
            civil_status_id: data.civil_status_id,
            job_title_id: data.job_title_id,
            job_industry_id: data.job_industry_id,
            length_with_current_employer_id:
              data.length_with_current_employer_id,
            length_with_previous_employer_id:
              data.length_with_previous_employer_id,
            address: data.address,
            unit: data.unit,
            zip_code: data.zip_code,
            housing_type_id: data.housing_type_id,
            housing_status_id: data.housing_status_id,
            length_of_stay_year_id: data.length_of_stay_year_id,
            is_own_property: data.is_own_property,
          }}
          validationSchema={Step2}
          onSubmit={async (values) => {
            handleOnNext(values);
          }}
        >
          {({ handleSubmit, setFieldValue, touched, errors, values }) => (
            <form className="space-y-8" onSubmit={handleSubmit}>
              <div>
                <SectionHeader title={"Personal Details"} />
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
                  <div>
                    <Input
                      name="nric_fin"
                      label="NRIC Number"
                      className="px-3.5 bg-white font-semibold !text-black"
                      color="blue"
                      size="lg"
                      placeholder="Enter your NRIC/FIN number"
                      value={values.nric_fin}
                      onChange={(e) =>
                        onChangeNRIC(setFieldValue, "nric_fin", e.target.value)
                      }
                      onBlur={() => {
                        vallidateNRIC(values.nric_fin);
                      }}
                      error={
                        Boolean(touched["nric_fin"] && errors["nric_fin"]) ||
                        error
                      }
                      type="text"
                    />
                    {touched["nric_fin"] && errors["nric_fin"] && (
                      <div className="text-red-600 mt-1 text-sm">
                        {errors["nric_fin"]}
                      </div>
                    )}
                    {error && (
                      <div className="text-red-600 mt-1 text-sm">
                        NRIC not a valid
                      </div>
                    )}
                  </div>
                  <InputSelectComponent
                    name="civil_status_id"
                    option={masterData.data["civil-statuses"]}
                    label="Civil Status"
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
              <div>
                <SectionHeader title={"Employment Details"} />
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
                  <InputSelectComponent
                    name="job_title_id"
                    option={masterData.data["job-titles"]}
                    label="Job Title"
                    setFieldValue={setFieldValue}
                  />
                  <InputSelectComponent
                    name="job_industry_id"
                    option={masterData.data["job-industries"]}
                    label="Job Industry"
                    setFieldValue={setFieldValue}
                  />
                  <InputSelectComponent
                    name="length_with_current_employer_id"
                    option={masterData.data["length-with-current-employer"]}
                    label="Length with Current Employer"
                    setFieldValue={setFieldValue}
                  />
                  <InputSelectComponent
                    name="length_with_previous_employer_id"
                    option={masterData.data["length-with-previous-employer"]}
                    label="Length with Previous Employer"
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
              <div>
                <SectionHeader title={"Residential Details"} />
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
                  <div>
                    <Input
                      name="zip_code"
                      label="Postal Code"
                      className="px-3.5 bg-white font-semibold !text-black"
                      color="blue"
                      size="lg"
                      placeholder="Enter your Postal Code"
                      value={values.zip_code}
                      onChange={(e) =>
                        onChangePostalCode(
                          setFieldValue,
                          "zip_code",
                          e.target.value
                        )
                      }
                      onBlur={() => {
                        FindAddress(values.zip_code, setFieldValue);
                      }}
                      error={Boolean(touched["zip_code"] && errors["zip_code"])}
                      type="text"
                    />
                    {touched["zip_code"] && errors["zip_code"] && (
                      <div className="text-red-600 mt-1 text-sm">
                        {errors["zip_code"]}
                      </div>
                    )}
                  </div>
                  <InputComponentCostume
                    name="unit"
                    label="Unit"
                    setFieldValue={setFieldValue}
                    placeholder="Enter your Unit Code"
                    size="lg"
                  />
                </div>
                <div className="mt-4">
                  <div>
                    <Input
                      name="address"
                      label="Address"
                      className="px-3.5 bg-white font-semibold !text-black"
                      color="blue"
                      size="lg"
                      placeholder="Enter your Address"
                      value={isAddressChanged ? values.address : addressData}
                      onChange={(e) => {
                        setIsAddressChanged(true);
                        setFieldValue("address", e.target.value);
                      }}
                      error={Boolean(touched["address"] && errors["address"])}
                      type="text"
                    />
                    {touched["address"] && errors["address"] && (
                      <div className="text-red-600 mt-1 text-sm">
                        {errors["address"]}
                      </div>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
                  <div>
                    <InputSelectComponent
                      name="housing_type_id"
                      option={masterData.data["housing-types"]}
                      label="Type of Housing"
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <div>
                    <InputSelectComponent
                      name="housing_status_id"
                      option={masterData.data["housing-statuses"]}
                      label="Housing Status"
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <div>
                    <InputSelectComponent
                      name="length_of_stay_year_id"
                      option={masterData.data["length-of-stays"]}
                      label="Length of Stay (Years)"
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <div>
                    <Select
                      label="Do You Own a Property?"
                      color="blue"
                      className="bg-white font-semibold !text-black px-3.5"
                      size="lg"
                      value={values.is_own_property ? "yes" : "no"}
                      onChange={(val) =>
                        onChangeSelect(setFieldValue, "is_own_property", val)
                      }
                      error={Boolean(
                        touched["is_own_property"] && errors["is_own_property"]
                      )}
                    >
                      <Option value="yes">Yes</Option>
                      <Option value="no">No</Option>
                    </Select>
                    {touched["is_own_property"] &&
                      errors["is_own_property"] && (
                        <div className="text-red-600 mt-1 text-sm">
                          {errors["is_own_property"]}
                        </div>
                      )}
                  </div>
                </div>
              </div>

              <SectionButtonStep
                step={step}
                onGoNext={
                  <Button
                    type="submit"
                    size="md"
                    className="bg-primary text-white flex items-center pl-8 pr-6 py-2 font-poppins font-medium rounded-xl tracking-wide"
                  >
                    Next
                    <IconChevronRight />
                  </Button>
                }
                onGoPrev={handleOnPrev}
              />
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SecondStep;
