import React, { useEffect, useState } from "react";
import { Typography } from "@material-tailwind/react";
import { IconInfoCircle } from "@tabler/icons-react";
import InputUploadDocument from "./InputUploadDocument";
import { get } from "lib/network/api";
import { API_URL } from "lib/network/api";
import TitlePage from "components/TitlePage";
import toast from "react-hot-toast";

const DocumentUpload = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const fetchDocument = () => {
    setLoading(true);
    get(`${API_URL}/user-documents`)
      .then((res) => {
        setData(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDocument();
  }, []);

  if (loading) {
    return (
      <div>
        <TitlePage title="Document Upload" />
        <div className="max-w-3xl mx-auto my-10 flex flex-col gap-5">
          <div className="relative flex w-full h-fit flex-col gap-4 bg-white bg-clip-border p-6 rounded-3xl shadow-xl">
            {[...Array(3)].map((item, idx) => {
              return (
                <div className="animate-pulse" key={idx}>
                  <Typography
                    as="div"
                    variant="h1"
                    className="h-[52px] rounded-xl bg-gray-300"
                  >
                    &nbsp;
                  </Typography>
                </div>
              );
            })}
            <div className="rounded-xl p-8 flex flex-row items-center gap-6 bg-[#F0F7FF] w-full flex-1">
              <IconInfoCircle className="text-primary w-10 h-10" />
              <span className="font-medium text-sm">
                Note: you can always revisit this page to upload document later
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <TitlePage title="Document Upload" />
      <div className="max-w-3xl mx-auto my-10 flex flex-col gap-5">
        <div className="relative flex w-full h-fit flex-col gap-4 bg-white bg-clip-border p-6 rounded-3xl shadow-xl">
          <InputUploadDocument
            apiUrl={`${API_URL}/user-documents/credit-bureau-singapore`}
            title={`${
              data?.credit_bureau_singapore
                ? "Download Credit Bureau Singapore"
                : "Credit Bureau Singapore (CBS)"
            }`}
            name="Credit Bureau Singapore (CBS)"
            id="credit_bureau_singapore"
            data={data?.credit_bureau_singapore}
            onUploadFetch={fetchDocument}
          />
          <InputUploadDocument
            apiUrl={`${API_URL}/user-documents/moneylenders-credit-bureau`}
            title={`${
              data?.money_lenders_credit_bureau
                ? "Download Moneylenders Credit Bureau"
                : "Moneylenders Credit Bureau (MLCB)"
            }`}
            name="Moneylenders Credit Bureau (MLCB)"
            id="money_lenders_credit_bureau"
            data={data?.money_lenders_credit_bureau}
            onUploadFetch={fetchDocument}
          />
          <InputUploadDocument
            apiUrl={`${API_URL}/user-documents/proof-of-income`}
            title={`${
              data?.proof_of_income
                ? "Download Proof of Income"
                : "Proof of Income"
            }`}
            name="Proof of Income"
            id="proof_of_income"
            data={data?.proof_of_income}
            onUploadFetch={fetchDocument}
          />
          <div className="rounded-xl p-8 flex flex-row items-center gap-6 bg-[#F0F7FF] w-full flex-1">
            <IconInfoCircle className="text-primary w-10 h-10" />
            <span className="font-medium text-sm">
              Note: you can always revisit this page to upload document later
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentUpload;
